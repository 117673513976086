import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import Sidebar from '../../../Layout/Sidebar';
import { addNewAndArticleApi, getAllCategoryApi, updateNewsByIdApi, uploadImage } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Spinwheel from '../../common/Spinwheel';
import JoditEditor from 'jodit-react';
import { newsUploadInitialValues, newsUploadValidationSchema } from '../../common/Validation';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import Header from '../../common/Header';
import Select from 'react-select';


function AddNews() {
  const [fileTypeError, setFileTypeError] = useState({ image: "", video: "" })
  const navigate = useNavigate()
  const [addSpin, setAddSpin] = useState(false)
  const [videoUpload, setVideoUpload] = useState(false);
  const location = useLocation(); 
  const [reRendor,setReRender] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([])
  let path = location.pathname
  path = path.split("/").slice(-1)[0];
  path = (path[0].toUpperCase() + path.slice(1))
  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      placeholder: 'Start writing...',
      askBeforePasteFromWord: true,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true,
      STATUSES: {
        beforeDestruct: "beforeDestruct",
        beforeInit: "beforeInit",
        destructed: "destructed",
        ready: "ready",
      }
    }
  }, []
  );



  if ((path === "Update") && location.state == null) {
    navigate("/news/add");
  }

  const handleSubmit = async (values) => {

    try {
      let res;
      const payload = { ...values, categoryId: values.categoryId?.value }
      if (path === "Add") {
        setAddSpin(true)
        res = await addNewAndArticleApi(payload)
      } else {
        setAddSpin(true)
        res = await updateNewsByIdApi(payload);
      }
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setAddSpin(false)
        navigate("/news", { replace: true })
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddSpin(false)
    }
  }

  const handleVideo = async (e) => {
    try {
      setVideoUpload(true)
      const file = e.target.files[0];
      
      const types = ['video/mp4', 'video/avi', 'video/mov', 'video/webm'];
      if (file) {
        if (types.includes(file.type)) {
          setFileTypeError({ ...fileTypeError, video: "" });
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 4)
          formData.append("folder", "news")
          const res = await uploadImage('/users/fileUpload', formData)
          
          if (res.status === 200 && res.data?.status === 1) {
            formik.setFieldValue("video", res.data?.data?.fileName);
            formik.setFieldValue("videoTimeDuration", res.data?.data?.videoTimeDuration);
     
            toast.success("File uploaded");
          }
        } else {
          formik.setFieldValue("video", "");
          setFileTypeError({ ...fileTypeError, video: "Must be video only" })
          
        }
      }
      setVideoUpload(false);
    } catch (error) {
      setVideoUpload(false)
      console.log(error)
      if (error?.response === undefined) {
        toast.error("File size is too large")
      }
    }
   
    setReRender(!reRendor)
  }

  const handleThumbnail = async (e) => {
    try {
      const file = e.target.files[0];
      const types = ['image/jpeg', 'image/png', 'image/gif']
      if (file) {
        if (types.includes(file.type)) {
          setFileTypeError({ ...fileTypeError, image: "" });
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 3)
          formData.append("folder", "news")
          const res = await uploadImage('/users/fileUpload', formData)
          formik.setFieldTouched("thumbnail", true)
          if (res.status === 200 && res.data?.status === 1) {
            formik.setFieldValue("thumbnail", res.data?.data?.fileName)
            toast.success("File uploaded");
          }
        } else {
          formik.setFieldTouched("thumbnail", true)
          formik.setFieldValue("thumbnail", "")
          setFileTypeError({ ...fileTypeError, image: "Must be image only" });
        }
      }

    } catch (error) {
      formik.setFieldTouched("thumbnail", true)
      console.log(error)
      if (error?.response === undefined) {
        toast.error("File size is too large")
      }
    }
  }

  const getAllCategories = async (type) => {
    try {
      
      const res = await getAllCategoryApi(type = "news")
      if (res.status === 200) {
        const result = res.data?.data?.map((ele) => ({ label: ele?.name, value: ele?._id }))
        setCategoryOptions(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllCategories();
  },[])



  const formik = useFormik({
    initialValues: location.state ? location.state?.data : newsUploadInitialValues,
    onSubmit: handleSubmit,
    validationSchema: newsUploadValidationSchema,
  });

  
  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title={<>Info &amp; News</>} />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/news" className="breadcrumb-item">
                    Info &amp; news
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {path}-info &amp; news
                </li>
              </ol>
            </nav>
            <div>
              <h2 className="report">News details</h2>
            </div>
            <hr />

            <div className="col-12" style={{ zIndex: "1" }}>
              <form action="" onSubmit={formik.handleSubmit} >
                <div className="row row-cols-lg-3 row-cols-md-2">
                  <div>
                    <label htmlFor="newsTitle" className="form-label">Category</label>
                    <div className="position-relative">
                      <Select
                        className="basic-single"
                        name="busType"
                        options={categoryOptions}
                        value={formik.values.categoryId?.label ? formik.values.categoryId : undefined}
                        onChange={(e) => formik.setFieldValue("categoryId", e)}
                      />
                      {formik.touched.categoryId && formik.errors.categoryId ? (<div className='req-error'>{formik.errors.categoryId}</div>) : null}
                    </div>
                    <br />
                  </div>


                  <div>
                    <label
                      className="form-label text-start d-block"
                      htmlFor="type"
                    >
                      Type
                    </label>
                    <select name="type" id="type" className="form-select form-control" value={formik.values.type} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                      <option value="" >Select </option>
                      <option value="article">Article</option>
                      <option value="video">Video</option>
                      {/* {company?.map((ele, i) => <option key={ele?._id} value={ele?._id}>{ele?.name}</option>)} */}
                    </select>
                    {formik.touched.type && formik.errors.type ? (<div className='req-error'>{formik.errors.type}</div>) : null}
                    <br />
                  </div>

                  <div>
                    <label htmlFor="newsTitle" className="form-label">Title</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="newsTitle" placeholder="Enter title" name="newsTitle" value={formik.values.newsTitle} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                        <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                      </svg>
                      {formik.touched.newsTitle && formik.errors.newsTitle ? (<div className='req-error'>{formik.errors.newsTitle}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="newsHeading" className="form-label">Headline</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="newsHeading" placeholder="Enter headline" name="newsHeading" value={formik.values.newsHeading} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.newsHeading && formik.errors.newsHeading ? (<div className='req-error'>{formik.errors.newsHeading}</div>) : null}
                    </div>
                    <br />
                  </div>
                  <div>
                    <label htmlFor="publishBy" className="form-label">Publisher name</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="publishBy" placeholder="Enter publisher name" name="publishBy" value={formik.values.publishBy} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      </svg>
                      {formik.touched.publishBy && formik.errors.publishBy ? (<div className='req-error'>{formik.errors.publishBy}</div>) : null}
                    </div>

                    <br />
                  </div>
                  <div>
                    <label htmlFor="newsSource" className="form-label">News source</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="newsSource" placeholder="Enter news source" name="newsSource" value={formik.values.newsSource} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.newsSource && formik.errors.newsSource ? (<div className='req-error'>{formik.errors.newsSource}</div>) : null}
                    </div>
                    <br />
                  </div>
                  <div>
                    <label htmlFor="publishDate" className="form-label">Published date</label>
                    <div className="position-relative">
                      <ReactDatePicker
                        selected={formik.values.publishDate ? new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY").format()) : ""}
                        name='publishDate'
                        id="publishDate"
                        onChange={(e) => {
                          e ?
                            formik.setFieldValue("publishDate", moment(e).format("MM-DD-YYYY")) :
                            formik.setFieldValue("publishDate", "")
                        }}
                        onBlur={formik.handleBlur}
                        className='form-control w-100'
                        // isClearable={formik.values.publishDate === "" ? false : true}
                        placeholderText='Pickup date'
                        minDate={path === "Add" ? new Date() : new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY")) < new Date() ? new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY")) : new Date()}
                        autoComplete="off"
                        dateFormat="dd/MM/yyyy"
                      />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                      </svg>

                      {formik.touched.publishDate && formik.errors.publishDate ? (<div className='req-error'>{formik.errors.publishDate}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="thumbnail" className="form-label">Thumbnail image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik.values.thumbnail ? ImageUrl + formik.values.thumbnail : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                        {
                          formik.values.type === "video" &&
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik.setFieldValue("thumbnail", "undefined/image.svg") }} >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </>
                        }
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="thumbnail" className="form-control" name="thumbnail" accept="image/*" onChange={handleThumbnail} />
                        Upload
                      </div>
                    </div>
                    {formik.touched.thumbnail && (formik.errors.thumbnail || fileTypeError.image?.trim()?.length > 0) ? (<div className='req-error'>{fileTypeError.image?.trim()?.length > 0 ? fileTypeError.image : formik.errors.thumbnail}</div>) : null}
                    <br />
                  </div>

                  {formik.values.type === "video" && <div>
                    <label htmlFor="profile" className="form-label">Upload video</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        <div width="100px" height="70px">
                          {formik.values.video ? <video width="100px" height="70px" className="rounded" >
                            <source src={ImageUrl + formik.values.video} type="video/mp4"></source>
                          </video> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-reels" viewBox="0 0 16 16">
                            <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM1 3a2 2 0 1 0 4 0 2 2 0 0 0-4 0z" />
                            <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7zm6 8.73V7.27l-3.5 1.555v4.35l3.5 1.556zM1 8v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z" />
                            <path d="M9 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM7 3a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                          </svg>}
                        </div>
                      </div>
                      <br />
                      <div className="btn_upload">
                        <input type="file" id="video" className="form-control" name="video" accept="video/*" onChange={handleVideo} />
                        {videoUpload ? "Uploading" : "Upload"}
                      </div>
                    </div>
                    {fileTypeError.video?.trim()?.length > 0 ? (<div className='req-error'>{fileTypeError.video}</div>) : formik.touched.video && formik.errors.video &&  !formik.values.video && (<div className='req-error'>{formik.errors.video}</div>)}
                    <br />
                  </div>}


                  <div>
                    <div className='d-flex justify-content-between '>
                      <label htmlFor="isPublished" className="form-label"> Published/Unpublished</label>
                      <div className="form-check form-switch map-togle-container">
                        <input className="form-check-input" type="checkbox" name='isPublished' role="switch" id="isPublished" checked={formik.values.isPublished} onChange={formik.handleChange} />

                      </div>
                    </div>
                    <br />
                  </div>

                  <div>
                    <div className='d-flex justify-content-between '>
                      <label htmlFor="onPriority" className="form-label">Show in Top News</label>
                      <div className="form-check form-switch map-togle-container">
                        <input className="form-check-input" type="checkbox" name='onPriority' role="switch" id="onPriority" checked={formik.values.onPriority} onChange={formik.handleChange} />

                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                
                {/* <div className='d-flex justify-content-between '>
                  <label htmlFor="isBanner" className="form-label"> Mark as a Banner</label>
                  <div className="form-check form-switch map-togle-container">
                    <input className="form-check-input" type="checkbox" name='isBanner' role="switch" id="isBanner" checked={formik.values.isBanner} onChange={formik.handleChange} />

                  </div>
                </div> */}
                

                <label htmlFor="newsContent" className="form-label">Content</label>
                <div className="position-relative">

                  {/* <textarea className="form-control" id='newsContent' name='newsContent' value={formik.values.newsContent} onChange={formik.handleChange} onBlur={formik.handleBlur} ></textarea> */}
                  <JoditEditor
                    ref={editor}
                    config={config}
                    value={formik.values.newsContent}
                    id='Content'
                    onBlur={(e) => {
                      formik.setFieldTouched("newsContent", true);
                      e === "<p><br></p>" ? formik.setFieldValue("newsContent", "") : formik.setFieldValue("newsContent", e);
                    }}
                    onChange={(e) => { }}
                  />
                  {formik.touched.newsContent && formik.errors.newsContent ? (<div className='req-error'>{formik.errors.newsContent}</div>) : null}
                </div>
                <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNews