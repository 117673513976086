import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAdminContext } from '../context/adminContext';
import { BsShop } from 'react-icons/bs';
import { removeToken } from '../utils/HelperFuncitons';


export default function Sidebar(props) {
    const { adminDetail } = useAdminContext();
    const location = useLocation();
    let activeRoute = location.pathname;
    const navigate = useNavigate();
    const handleLogout = () => {
        removeToken();
        navigate("/");
        window.location.reload();
    }

    return (
        <>
            <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
                <div className="container-fluid">
                    {/* Toggler */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                    {/* Brand */}
                    <Link className="navbar-brand" to="/dashboard">
                        <img src="/images/logo.png" className="navbar-brand-img mx-auto rounded-circle" alt="" />
                    </Link>
                    {/* Collapse */}
                    <div className="collapse navbar-collapse" id="sidebarCollapse">
                        <ul className="navbar-nav">
                            {
                                <li className="nav-item">
                                    <Link to="/dashboard" className={`nav-link ${activeRoute?.split('/')?.includes("dashboard") || props?.hightlight === "dashboard" ? "jnl" : ""}`} role="button" aria-expanded="true" aria-controls="sidebarDashboards">
                                        <i className="fe fe-home" /> Dashboard
                                    </Link>

                                </li>}
                            {adminDetail?.role === "admin" && <li className="nav-item">
                                <Link to="/users" className={`nav-link ${activeRoute?.split('/')?.includes("users") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarPages">
                                    <i className="fe fe-users" /> Users
                                </Link>
                            </li>}
                            {/*<li className="nav-item">
                                <Link className={`nav-link ${activeRoute?.split('/')?.includes("administrators") ? "jnl" : ""}`} to="/administrators">
                                    <i className="fe" ><img src="/images/Group7634.svg" height={20} width={20} style={{ opacity: ".8" }} alt="" /></i> Administrators/Vendors
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className={`nav-link ${activeRoute?.split('/').includes("users") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth" >
                                    <i className="fe"><img src="/images/Group7635.svg" height={20} width={20} style={{ opacity: ".8" }} alt="" /></i> Module Management
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${activeRoute?.split('/').includes("users") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth" >
                                    <i className="fe" ><img src="/images/Group7636.svg" height={20} width={20} style={{ opacity: ".8" }} alt="" /></i> Page Management
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link to="/userreports" className={`nav-link ${activeRoute?.split('/')?.includes("userreports") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                    <i className="fe" ><img src="/images/Group7637.svg" height={20} width={20} style={{ opacity: ".8" }} alt="" /></i> System & Reports
                                </Link>
                            </li> */}
                            {
                                adminDetail?.role === "admin" &&
                                <li className="nav-item">
                                    <Link to="/essentialnumber" className={`nav-link ${activeRoute?.split('/')?.includes("essentialnumber") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg height={20} width={20} style={{ opacity: ".8" }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        </i>
                                        Essential number
                                    </Link>
                                </li>}


                            {
                                adminDetail?.role === "admin" &&

                                <li className="nav-item">
                                    <Link to="/sos" className={`nav-link ${activeRoute?.split('/')?.includes("sos") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">

                                            <svg xmlns="http://www.w3.org/2000/svg" height={20} width={20} fill="currentColor" className="bi bi-patch-question" viewBox="0 0 16 16">
                                                <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z" />
                                                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                                                <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                                            </svg>
                                        </i>
                                        SOS
                                    </Link>
                                </li>}
                            {/* <li className="nav-item d-md-none">
                                <a className={`nav-link ${activeRoute?.split('/').includes("users") ? "jnl" : ""}`} data-bs-toggle="offcanvas" href="#sidebarOffcanvasActivity" aria-contrtols="sidebarOffcanvasActivity">
                                    <span className="fe fe-bell" /> Notifications
                                </a>
                            </li> */}
                            {/* {
                                adminDetail?.role === "admin" &&
                                <li className="nav-item">
                                    <Link to="/document" className={`nav-link ${activeRoute?.split('/')?.includes("document") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-buildings" viewBox="0 0 16 16">
                                                <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                                                <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                                            </svg>
                                        </i>
                                        Document
                                    </Link>
                                </li>} */}
                            {
                                adminDetail?.role === "admin" &&
                                <li className="nav-item">
                                    <Link to="/map" className={`nav-link ${activeRoute?.split('/')?.includes("map") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                            </svg>
                                        </i>
                                        Map
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin" ||
                                    adminDetail?.role === "jobVendor"
                                ) && 
                                <li className="nav-item">
                                    <Link to="/job" className={`nav-link ${activeRoute?.split('/')?.includes("job") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-workspace" viewBox="0 0 16 16">
                                                <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                                            </svg>
                                        </i>
                                        Jobs
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin" || adminDetail?.role === "newsVendor") &&
                                <li className="nav-item">
                                    <Link to="/news" className={`nav-link ${activeRoute?.split('/')?.includes("news") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-newspaper" viewBox="0 0 16 16">
                                                <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z" />
                                                <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z" />
                                            </svg>
                                        </i>
                                        Info &amp; news
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin" || adminDetail?.role === "entertainmentVendor")&&
                                <li className="nav-item">
                                    <Link to="/entertainment" className={`nav-link ${activeRoute?.split('/')?.includes("entertainment") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-film" viewBox="0 0 16 16">
                                                <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z" />
                                            </svg>
                                        </i>
                                        Entertainment
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin" || adminDetail?.role === "livingVendor") &&
                                <li className="nav-item">
                                    <Link to="/living" className={`nav-link ${activeRoute?.split('/')?.includes("living") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                                            </svg>
                                        </i>
                                        Living
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin" || adminDetail?.role === "studyVendor") &&
                                <li className="nav-item">
                                    <Link to="/course" className={`nav-link ${activeRoute?.split('/')?.includes("course") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-book" viewBox="0 0 16 16">
                                                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                            </svg>
                                        </i>
                                        Study
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin") &&
                                <li className="nav-item">
                                    <Link to="/category" className={`nav-link ${activeRoute?.split('/')?.includes("category") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                            <span class="fe fe-layout"></span>
                                        Product category
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "shopVendor") &&
                                <li className="nav-item">
                                        <Link to="/product" className={`nav-link ${activeRoute?.split('/')?.includes("product") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <BsShop />
                                        </i>
                                        Products
                                    </Link>
                                </li>}

                            {
                                adminDetail?.role === "admin" && <li className="nav-item">
                                    <Link to="/vendor" className={`nav-link ${activeRoute?.split('/')?.includes("vendor") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                                            </svg>
                                        </i>
                                        Vendor
                                    </Link>
                                </li>
                            }


                            {(adminDetail?.role === "shopVendor") && <li className="nav-item">
                                <Link to="/orders" className={`nav-link ${activeRoute?.split('/')?.includes("orders") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                   <span className='fe fe-package'></span>
                                    Orders
                                </Link>
                            </li>}

                            {adminDetail?.role === "admin" && <li className="nav-item">
                                <Link to="/pages" className={`nav-link ${activeRoute?.split('/')?.includes("pages") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                    <span className='fe fe-layers'></span>
                                    Page management
                                </Link>
                            </li>}

                            {adminDetail?.role === "admin" && <li className="nav-item">
                                <Link to="/systemreports" className={`nav-link ${activeRoute?.split('/')?.includes("systemreports") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                    <span class="fe fe-trending-up"></span>
                                    System and reports
                                </Link>
                            </li>}


                            {
                                adminDetail?.role === "admin" &&
                                <li className="nav-item">
                                    <Link to="/admin-user" className={`nav-link ${activeRoute?.split('/')?.includes("admin-user") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                                            </svg>
                                        </i>
                                        Admins
                                    </Link>
                                </li>}
                            {
                                adminDetail?.role === "admin" &&
                                <li className="nav-item">
                                    <Link to="/transfers" className={`nav-link ${activeRoute?.split('/')?.includes("transfers") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                            </svg>

                                        </i>
                                        Transfers
                                    </Link>
                                </li>}

                            {
                                adminDetail?.role === "admin" &&
                                <li className="nav-item">
                                    <Link to="/notifications" className={`nav-link ${activeRoute?.split('/')?.includes("notifications") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                        <i className="fe" width="16" height="16">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                            </svg>

                                        </i>
                                        Notificatons
                                    </Link>
                                </li>}
                            {
                                (adminDetail?.role === "admin" || adminDetail?.role === "ticketVendor") &&
                                <li className="text-white">
                                    <div class="accordion" style={{border:"none"}} id="accordionFlushExample">
                                        <div class="accordion-item main-bg" style={{border:"none"}}>
                                            <h2  className={`accordion-header main-bg ${activeRoute?.split('/')?.includes("ticket") ? "jnl" : ""}`} id="flush-headingOne">
                                                <button class="accordion-button collapsed main-bg text-white nav-item" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                 <div >
                                                    <i className="bi" width="16" height="16" style={{paddingRight:"1rem"}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ticket-perforated" viewBox="0 0 16 16">
                                                                <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z" />
                                                                <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z" />
                                                            </svg>
                                                    </i>
                                             Ticket
                                                 </div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="">
                                                    <Link to="/ticket/bus/tickets" className={`nav-link ${activeRoute?.split('/')?.includes("bus") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                                        <i className="fe" width="16" height="16">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                                            </svg>

                                                        </i>
                                                        Bus
                                                    </Link>
                                                </div>
                                                <div class="">
                                                    <Link to="/ticket/train/tickets" className={`nav-link ${activeRoute?.split('/')?.includes("train") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                                        <i className="fe" width="16" height="16">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                                            </svg>

                                                        </i>
                                                        Train
                                                    </Link>
                                                </div>
                                                <div class="">
                                                    <Link to="/ticket/flight/booking" className={`nav-link ${activeRoute?.split('/')?.includes("flight") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                                        <i className="fe" width="16" height="16">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                                            </svg>

                                                        </i>
                                                        Flight
                                                    </Link>
                                                </div>
                                                <div class="">
                                                    <Link to="/ticket/movie/tickets" className={`nav-link ${activeRoute?.split('/')?.includes("movie") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                                        <i className="fe" width="16" height="16">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                                            </svg>

                                                        </i>
                                                        Movies
                                                    </Link>
                                                </div>
                                                <div class="">
                                                    <Link to="/ticket/hotel/booking" className={`nav-link ${activeRoute?.split('/')?.includes("hotel") ? "jnl" : ""}`} role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                                        <i className="fe" width="16" height="16">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                                            </svg>

                                                        </i>
                                                        Hotel
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>}

                           
                        </ul>
                        <br /><br />
                        <br /><br />
                        {/* Heading */}
                        {/* <h6 className="navbar-heading">
                            RECENT
                        </h6>
                        <hr className="navbar-divider mt-0 mb-4" /> */}
                        {/* Navigation */}
                        {/* <div className="px-2">
                            <button className="not-recent">Not recent updates</button>
                        </div>
                        <br /> */}
                        {/* Push content down */}
                        <div className="mt-auto" />
                        {/* <hr className="navbar-divider mt-0 mb-4" /> */}
                        {/* User (md) */}
                        <Link to="/" data-bs-toggle="modal" data-bs-target="#exampleModalout" className='text-white mb-4' ><i className="fe me-2"> <img src='/images/log.svg' width={20} height={20} alt="" /></i>Log Out</Link>
                    </div> {/* / .navbar-collapse */}
                </div>
            </nav>

            {/* Log out popup code start here */}
            <div className="modal fade" id="exampleModalout" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body pe-0 ps-0 block-user">
                            <div className='logout-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="rotate(180 12 12)"><g id="feLogout0" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1"><g id="feLogout1" fill="currentColor"><path id="feLogout2" d="M3 5c0-1.1.9-2 2-2h8v2H5v14h8v2H5c-1.1 0-2-.9-2-2V5Zm14.176 6L14.64 8.464l1.414-1.414l4.95 4.95l-4.95 4.95l-1.414-1.414L17.176 13H10.59v-2h6.586Z" /></g></g></g></svg>
                            </div>
                            {/* <h1 className="mb-0">Log Out?</h1> */}
                            <p><b>Are you sure you want to log out ?</b></p>
                        </div>
                        <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
                            <button type="button" className="btn-custom w-auto" onClick={handleLogout}>Yes</button>
                            <button type="button" className="btn-custom btn-custom-ops w-auto" data-bs-dismiss="modal" aria-label="Close">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
