import React, { useState } from 'react'
import { ImageUrl } from '../../../../../utils/BaseUrl'
import { uploadImage } from '../../../../.././utils/ApiEndpointFunctions'
import toast from 'react-hot-toast'
import Spinwheel from '../../../../common/Spinwheel'

function Category({ formik, addLoader, modalId }) {
  const [reRender, setRerender] = useState(false)
  const [fileTypeError, setFileTypeError] = useState("")
  
  const imageUpload = async (e) => {
    try {
      formik?.setFieldTouched(`image`, true)
      const file = e.target.files[0];
      const types = ['image/jpeg', 'image/png', 'image/gif']
      if (file) {
        if (types.includes(file?.type)) {
          setFileTypeError("")
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 2)
          formData.append("folder", "document")
          const res = await uploadImage('/users/fileUpload', formData)
          if (res.status === 200 && res.data?.status === 1) {
            formik?.setFieldValue("image", res.data?.data?.fileName)
            toast.success("File uploaded")
          }
        } else {
          formik?.setFieldValue("image", "")
          formik?.setFieldTouched(`image`, true)
          setFileTypeError("Must be image only")
          setRerender(!reRender)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body block-user">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' onSubmit={formik?.handleSubmit} >
                <div className='d-flex justify-content-start'>
                  <h2 style={{ textAlign: "left !important" }}>
                    Category
                  </h2>
                </div>
                <div className='d-flex justify-content-start'><label htmlFor="profile" className="form-label text-align-start"> Image</label></div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="position-relative">
                    <img src={formik?.values?.image ? ImageUrl + encodeURIComponent(formik?.values?.image) : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="70px" style={{ objectFit: "contain" }} className="rounded-circle" alt="Selected file" />
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16"  >
                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" style={{ visibility: "hidden" }} >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </>
                  </div>
                  <div className="btn_upload">
                    <input type="file" id="profile" className="form-control" accept="image/*" name="image" onChange={imageUpload} />
                    Upload
                  </div>
                </div>
                {fileTypeError ? (<div className='req-error'>{fileTypeError}</div>) : formik?.touched?.image && formik?.errors?.image ? (<div className='req-error'>{formik?.errors?.image}</div>) : null}
                <br />
                <div className='d-flex justify-content-start'>  <label htmlFor="name" className="form-label"> Name</label></div>
                <input name="name" id="name" className="form-control border mb-2" placeholder='Enter Category' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}

                <div className='d-flex justify-content-start'>  <label htmlFor="description" className="form-label"> Description</label></div>
                <textarea id="description" name='description' class="form-control border" placeholder="Leave a comment here" value={formik?.values?.description} onChange={formik?.handleChange} ></textarea>

                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="submit" className="btn-custom w-auto" disabled={addLoader} >{addLoader ? <Spinwheel sizeClass="spin-small"/> : "Submit"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category