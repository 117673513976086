import { API_BASE_URL } from "./BaseUrl"
import { postApiWithoutToken, getApiWithToken, putApiWithToken, postApiWithToken, deleteApi, putApiWithoutToken, getApiWithoutToken } from "./ApiInterfaces"
import axios from "axios";
import { getToken } from "./HelperFuncitons";

export const signIN = async (values) => {
    const res = await postApiWithoutToken("/admin/signin", values)
    return res;
}

export const adminUserAddApi = async(values) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRNZXNzYWdlIjoiYWRtaW5TaWdudXBGb3JET0UiLCJpYXQiOjE2ODk3NDgyNjB9.OQVeW3RlY2GKoO6MUBQOk3pDqphIdBhRXf2wIVWNZuk"
    return axios.post(API_BASE_URL + `/admin/signup`, values, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
}

export const getAdminDetails = async () => {
    const res = await getApiWithToken("/admin/getAdminDetails")
    return res;
}

export const adminProfileUpdate = async (values) => {
    const res = await putApiWithToken("/admin/updateProfile", values)
    return res;
}

export const forgetPassword = async (values) => {
    const res = await postApiWithoutToken("/admin/forgetPassword", values)
    return res;
}

export const variedOtp = async (values) => {
    const res = await putApiWithoutToken("/admin/otpVerification", values)
    return res;
}

export const getTolalUserVendorAndActiveVendorApi = async () => {
    return getApiWithToken(`/admin/getTolalUserVendorAndActiveVendor`)
} 

export const inviteVendorByEmailApi = async (values) => {
    const res = await postApiWithToken("/admin/inviteVendorByEmail", values)
    return res;
}

export const createOrUpdateNotificationApi = async (values) => {
    const res = await postApiWithToken("/admin/notification/createOrUpdateNotification", values)
    return res;
}

export const updateVendorByIdApi = async (values) => {
    const res = await putApiWithToken("/admin/updateAdminUserById", values)
    return res;
}

export const createVendorUserApi = async (values) => {
    const res = await postApiWithToken("/admin/createAdminUsers", values)
    return res;
}

export const removeVendorByIdApi = async (id="") => {
    const res = await deleteApi(`/admin/removeVendorById?_id=${id}`)
    return res;
}

export const uploadImage = async (path, data) => {
    const token = getToken();
    return axios.post(API_BASE_URL + path, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
    });
}

export const addEssential = async (values) => {
    const res = await postApiWithToken("/admin/essentialNumber/addEssentialNumbers", values)
    return res
}

export const editEssential = async (values) => {
    const res = await putApiWithToken('/admin/essentialNumber/updateEssentialNumbers', values)
    return res;
}

export const getEssential = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/essentialNumber/getAllEssentialNumbers?name=${search}&page=${page}&limit=${limit}`);
    return res;
}

export const deleteEssentail = async (values) => {
    const res = await deleteApi('/admin/essentialNumber/deleteEssentialNumbers', { _id: values })
    return res;
}

export const addApplinks = async (values) => {
    const res = await postApiWithToken("/admin/essentialNumber/addApplinks", values)
    return res
}

export const editApplinks = async (values) => {
    const res = await putApiWithToken('/admin/essentialNumber/updateApplinks', values)
    return res;
}

export const getApplinks = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/essentialNumber/getAllApplinks?name=${search}&page=${page}&limit=${limit}`);
    return res;
}

export const deleteApplinks = async (values) => {
    const res = await deleteApi('/admin/essentialNumber/deleteApplinks', { _id: values })
    return res;
}

export const getAllSosUsers = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/sos/getAllUserSos?page=${page}&limit=${limit}&search=${search}`);
    return res;
}

export const getSosLimitationApi = async () => {
    const res = await getApiWithToken(`/admin/sos/getSosLimitation`)
    return res
}

export const resetPassword = async (values) => {
    return axios.put(API_BASE_URL + "/admin/resetPassword", { password: values?.password }, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${values?.token}`,
        },
    });

}

export const getAllUsers = async (limit, page, search = "",sort) => {
    const res = await getApiWithToken(`/admin/getAllUser?page=${page}&limit=${limit}&search=${search}&sort=${sort}`);
    return res;
}


export const getAllDocuments = async () => {
    const res = await getApiWithToken("/admin/documents/getAllDocuments")
    return res
}

export const addOfficesAndEmbassies = async (values) => {
    const res = await postApiWithToken('/admin/documents/addofficesAndEmbassies', values)
    return res;
}


export const deleteOfficesAndEmbassiesDetailsByIdApi = async (values) => {
    const res = await deleteApi(`/admin/documents/deleteOfficesAndEmbassiesDetailsById?id=${values?.id}&myDocumentId=${values?.myDocumentId}`)
    return res
}

export const deleteUserUploadDocumentApi = async (id = "") => {
    const res = await deleteApi(`/admin/documents/deleteUserUploadDocument?documentId=${id}`)
    return res
}

export const deleteUserDocsApplicationApi = async (id = "") => {
    const res = await deleteApi(`/admin/documents/deleteUserDocsApplication?applicationId=${id}`)
    return res
}

export const updateOfficesAndEmbassiesDetailsByIdApi = async (values) => {
    const payload = { ...values, id: values?._id }
    delete payload?._id;
    const res = await putApiWithToken(`/admin/documents/updateOfficesAndEmbassiesDetailsById`, payload)
    return res;
}

export const getAllOfficesAndEmbassiesDetailsByDocIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/documents/getAllOfficesAndEmbassiesDetailsByDocId?myDocumentId=${id}`);
    return res;
}

export const getDocsCategorytypeApi = async () => {
    const res = await getApiWithToken(`/admin/documents/getDocsCategorytype`);
    return res;
}
export const getDocsCategoryByTypeApi = async (type="", typeNo="", search="") => {
    const res = await getApiWithToken(`/admin/documents/getDocsCategoryByType?type=${type}&typeNo=${typeNo}&search=${search}`);
    return res;
}
export const getDocumentByCategoryIdApi = async (userId="",categoryId="") => {
    const res = await getApiWithToken(`/admin/documents/getDocumentByCategoryId?userId=${userId}&categoryId=${categoryId}`);
    return res;
}

export const updateFormPriceByIdApi = async (values) => {
    const res = await putApiWithToken("/admin/documents/updateFormPriceById", values)
    return res;
}

export const getFormApi = async (userId="", category="", form="") => {
    const res = await getApiWithToken(`/admin/forms/getForm?userId=${userId}&Category=${category}&form=${form}`);
    return res;
}

// export const createAndUpdateDocumentApplicationApi = async (values) => {
//     const res = await postApiWithToken('/admin/documents/createAndUpdateDocumentApplication', values);
//     return res;
// }
export const createAndUpdateDocumentApplicationApi = async (values) => {
    const res = await postApiWithToken('/admin/forms/addform', values);
    return res;
}

export const getCategoryFeildDataApi = async (category="",) => {
    const res = await getApiWithoutToken(`/admin/documents/getCategoryFeildData?category=${category}`);
    return res;
}

export const addMapData = async (values) => {
    const res = await postApiWithToken('/admin/map/addMapData', values)
    return res;
}

export const getMapData = async (limit, page, search = "", type) => {
    const res = await getApiWithToken(`/admin/map/getMapData?search=${search}&page=${page}&limit=${limit}&type=${type}`);
    return res;
}

export const getMapDataBySearch = async (limit, page, search = "", type) => {
    const res = await getApiWithToken(`/admin/map/getMapDataBySearch?search=${search}&page=${page}&limit=${limit}&type=${type}`);
    return res;
}

export const updateMapData = async (values) => {
    const payload = { ...values, isPinned: values?.isPinned?.toString?.()}
    const res = await putApiWithToken('/admin/map/updateMapData', payload)
    return res;
}

export const deleteMapById = async (id) => {
    const res = await deleteApi(`/admin/map/deleteMapDataById?id=${id}`)
    return res;
}

export const getMapDataDetailsById = async (id) => {
    const res = await getApiWithToken(`/admin/map/getMapDataDetailsById?id=${id}`);
    return res;
}

export const addCompanyApi = async (values) => {
    const res = await postApiWithToken('/admin/company/addCompany', values)
    return res;
}
export const getAllCompaniesListApi = async () => {
    const res = await getApiWithToken("/admin/company/getAllCompaniesList?search=")
    return res;
}

export const getCompanyDetailsByIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/company/getCompanyDetailsById?id=${id}`)
    return res;
}

export const updateSalaryRangeSelectorApi = async (values) => {
    const res = await putApiWithToken("/admin/jobs/updateSalaryRangeSelector", values);
    return res;
}

export const addJobsApi = async (values) => {
    const res = await postApiWithToken("/admin/jobs/addJobs", values);
    return res;
}

export const getJobDetailsByIdApi = async (id,search="") => {
    const res = await getApiWithToken(`/admin/jobs/getJobDetailsById?id=${id}&search=${search}`);
    return res;
}

export const deleteJobByIdApi = async (id) => {
    const res = await deleteApi(`/admin/jobs/deleteJobById?id=${id}`);
    return res;
}

export const updateJobDetailsByIdApi = async (values) => {
    const res = await putApiWithToken("/admin/jobs/updateJobDetailsById", values);
    return res;
}

export const getAllJobListApi = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/jobs/getAllJobList?search=${search}&page=${page}&limit=${limit}`);
    return res;
}

export const getAllReviewsByJobIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/reviews/getAllReviewsByJobId?id=${id}`);
    return res;
}

export const getAllReviewsByCompanyIdApi = async (id = "") => {
    const res = await getApiWithToken(`/admin/reviews/getAllReviewsByCompanyId?_id=${id}`);
    return res;
}

export const deleteReviewById = async (id) => {
    const res = await deleteApi(`/admin/reviews/deleteReviewById?id=${id}`);
    return res;
}

export const addReviewAndRatingApi = async (values) => {
    values.rating = values.rating.toString()?.includes('.') ? values?.rating : values.rating.toString() + ".0"
    const res = await postApiWithToken("/admin/reviews/addReviewAndRating", values)
    return res;
}

export const updateReviewByIdApi = async (values) => {
    const payload = {
        id: values?.id,
        name: values?.name,
        rating: values.rating.toString()?.includes('.') ? values?.rating : values.rating.toString() + ".0",
        review: values?.review
    }
    const res = await putApiWithToken("/admin/reviews/updateReviewById", payload)
    return res;
}

export const updateUserApplicationStatusApi = async (values) => {
    const res = await putApiWithToken("/admin/jobs/updateUserApplicationStatus", values)
    return res;
}

export const addSosMessageByAdminApi = async (values) => {
    const res = await postApiWithToken("/admin/sos/addSosMessageByAdmin", values)
    return res
}

export const getAllSosMessagesByAdminApi = async (values) => {
    const res = await getApiWithToken("/admin/sos/getAllSosMessagesByAdmin");
    return res;
}

export const deleteSosMessagesByIdApi = async (id) => {
    const res = await deleteApi(`/admin/sos/deleteSosMessagesById?id=${id}`)
    return res;
}

export const updateSosMessagesByIdApi = async (values) => {
    const res = await putApiWithToken("/admin/sos/updateSosMessagesById", values)
    return res;
}
export const getAllNewsApi = async (limit, page, search = "", type="") => {
    const res = await getApiWithToken(`/admin/newsAndArticle/getAllNews?search=${search}&page=${page}&limit=${limit}&type=${type}`);
    return res;
}


export const getAllAddedCarouselNewsApi = async (limit="", page="", search = "", type="") => {
    return await getApiWithToken(`/admin/newsAndArticle/getAllAddedCarouselNews?search=${search}&page=${page}&limit=${limit}&type=${type}`);
}

export const getAllUnCarouselNewsApi = async (search = "", type = "") => {
    return await getApiWithToken(`/admin/newsAndArticle/getAllUnCarouselNews?search=${search}&type=${type}`);
}

export const addNewsToCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/newsAndArticle/addNewsToCarousel`,payload)
}

export const updateNewsCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/newsAndArticle/updateNewsCarousel`,payload)
}

export const removeNewsFromCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/newsAndArticle/removeNewsFromCarousel`,payload)
}


export const addNewAndArticleApi = async (values) => {
    const res = await postApiWithToken("/admin/newsAndArticle/addNewAndArticle", values)
    return res;
}

export const updateNewsByIdApi = async (values) => {
    let obj = { ...values }
    obj.isPublished = obj?.isPublished?.toString()
    obj.isBanner = obj?.isBanner?.toString()
    obj.onPriority = obj?.onPriority?.toString()
    const res = await putApiWithToken("/admin/newsAndArticle/updateNewsById", obj)
    return res
}

export const deleteNewsByIdApi = async (id) => {
    const res = await deleteApi(`/admin/newsAndArticle/deleteNewsById?id=${id}`)
    return res
}

export const updatePriorityApi = async (values) => {
    const res = await putApiWithToken('/admin/newsAndArticle/updatePriority', values)
    return res
}

export const publishOrUnpublishApi = async (values) => {
    const res = await putApiWithToken('/admin/newsAndArticle/publishOrUnpublish', values)
    return res
}

export const bannerOnOffApi = async (values) => {
    const res = await putApiWithToken('/admin/newsAndArticle/bannerOnOff', values)
    return res
}

export const addApplicationFormApi = async (values) => {
    const res = postApiWithToken('/admin/documents/addApplicationForm', values)
    return res
}

export const addVideosAndInshortsApi = async (values) => {
    const res = await postApiWithToken("/admin/entertainment/addVideosAndInshorts", values)
    return res;
}

export const updateVideoAndInshortsByIdApi = async (values) => {
    let obj = { ...values }
    obj.isPublish = obj?.isPublish?.toString()
    obj.isBanner = obj?.isBanner?.toString()
    const res = await putApiWithToken("/admin/entertainment/updateVideoAndInshortsById", obj)
    return res
}

export const getAllVideoAndInshortsApi = async (limit, page, search = "", type="") => {
    const res = await getApiWithToken(`/admin/entertainment/getAllVideoAndInshorts?search=${search}&page=${page}&limit=${limit}&type=${type}`);
    return res;
}

export const deleteVideoAndInshortsByIdApi = async (id) => {
    const res = await deleteApi(`/admin/entertainment/deleteVideoAndInshortsById?id=${id}`)
    return res
}

export const publishOrUnpublishVideoAndInshorts = async (values) => {
    const res = await putApiWithToken('/admin/entertainment/publishOrUnpublishVideoAndInshorts', values)
    return res
}

export const bannerOnOffVideoAndInshorts = async (values) => {
    const res = await putApiWithToken('/admin/entertainment/bannerOnOffVideoAndInshorts', values)
    return res
}

export const getAllAddedCarouselEntertainmentApi = async (limit = "", page = "", search = "", type = "") => {
    return await getApiWithToken(`/admin/entertainment/getAllAddedCarouselEntertainment?search=${search}&page=${page}&limit=${limit}&type=${type}`);
}

export const getAllUnCarouselEntertainmentApi = async (search = "", type = "") => {
    return await getApiWithToken(`/admin/entertainment/getAllUnCarouselEntertainment?search=${search}&type=${type}`);
}

export const addEntertainmentToCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/entertainment/addEntertainmentToCarousel`, payload)
}

export const updateEntertainmentCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/entertainment/updateEntertainmentCarousel`, payload)
}

export const removeEntertainmentFromCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/entertainment/removeEntertainmentFromCarousel`, payload)
}

export const setSosLimitationApi = async (values) => {
    const res = postApiWithToken("/admin/sos/setSosLimitation", values)
    return res;
}

export const updateJobStatusApi = async (values) => {
    const res = await putApiWithToken('/admin/jobs/updateJobStatus', values)
    return res;
}

export const getAllIndustryAndSkillsByType = async (type) => {
    const res = await getApiWithToken(`/admin/company/getAllIndustryAndSkillsByType?type=${type}`)
    return res
}

export const addIndustryAndSkillsApi = async (value) => {
    const res = await postApiWithToken(`/admin/company/addIndustryAndSkills`, value)
    return res
}

export const addDocByAdminApi = async (values) => {
    const res = postApiWithToken('/admin/documents/addDocByAdmin', values);
    return res;
}

export const updateDocumentByAdmin = async (values) => {
    const res = putApiWithToken(`/admin/documents/updateDocumentByAdmin`, values)
    return res;
}

export const deleteDocumentByIdApi = async (id) => {
    const res = await deleteApi(`/admin/documents/deleteDocumentById?id=${id}`)
    return res
}

export const addVideosAndArticlesApi = async (values) => {
    const res = await postApiWithToken("/admin/living/addVideosAndArticles", values)
    return res;
}

export const updateVideoAndArticlesByIdApi = async (values) => {
    let obj = { ...values }
    obj.isPublish = obj.isPublish?.toString()
    obj.isBanner = obj.isBanner?.toString()
    obj.isPopular = obj.isPopular?.toString()
    const res = await putApiWithToken("/admin/living/updateVideoAndArticlesById", obj)
    return res
}

export const getAllVideoAndArticlesApi = async (limit, page, search = "", type="") => {
    const res = await getApiWithToken(`/admin/living/getAllVideoAndArticles?search=${search}&page=${page}&limit=${limit}&type=${type}`);
    return res;
}

export const deleteVideoAndArticlesByIdApi = async (id) => {
    const res = await deleteApi(`/admin/living/deleteVideoAndArticlesById?id=${id}`)
    return res
}

export const publishOrUnpublishVideoAndArticles = async (values) => {
    const res = await putApiWithToken('/admin/living/publishOrUnpublishVideoAndArticles', values)
    return res
}

export const bannerOnOffVideoAndArticles = async (values) => {
    const res = await putApiWithToken('/admin/living/bannerOnOffVideoAndArticles', values)
    return res
}

export const getAllAddedCarouselLivingApi = async (limit = "", page = "", search = "", type = "") => {
    return await getApiWithToken(`/admin/living/getAllAddedCarouselLiving?search=${search}&page=${page}&limit=${limit}&type=${type}`);
}

export const getAllUnCarouselLivingApi = async (search = "", type = "") => {
    return await getApiWithToken(`/admin/living/getAllUnCarouselLiving?search=${search}&type=${type}`);
}

export const addLivingToCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/living/addLivingToCarousel`, payload)
}

export const updateLivingCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/living/updateLivingCarousel`, payload)
}

export const removeLivingFromCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/living/removeLivingFromCarousel`, payload)
}

export const addCoursesApi = async (values) => {
    const res = await postApiWithToken(`/admin/courses/addCourses`, values)
    return res;
}

export const getAllCoursesAndChapterList = async (limit, page, search = "") => {
    const res = getApiWithToken(`/admin/courses/getAllCoursesAndChapterList?page=${page}&limit=${limit}&search=${search}`)
    return res;
}

export const updateCourseApi = async (values) => {
    let obj = { ...values }
    obj.isPublished = obj?.isPublished?.toString()
    obj.isBanner = obj?.isBanner?.toString()
    obj.isPopular = obj?.isPopular?.toString()
    const res = putApiWithToken(`/admin/courses/updateCourse`, obj)
    return res;
}

export const updateCourseStatusApi = async (values) => {
    const res = putApiWithToken(`/admin/courses/updateCourseStatus`, values)
    return res;
}

export const getAllChaptersByCourseIdApi = async (id) => {
    const res = getApiWithToken(`/admin/courses/getAllChaptersByCourseId?courseId=${id}`)
    return res
}

export const addChaptersByCourseIdApi = async (values) => {
    const res = await postApiWithToken(`/admin/courses/addChaptersByCourseId`,values)
    return res;
}

export const updateCourseChaptersApi = async (values,order = 0) => {
    let payload
    if(order === 0) {
        payload = { id: values.courseId, courseChapter: values.courseChapter }
    } else {
        payload = { id: values[0].courseId, courseChapter: values  }
    }
    const res = await putApiWithToken(`/admin/courses/updateCourseChapters`, payload)
    return res;
}

export const deleteCourseApi = async (id) => {
    const res = await deleteApi(`/admin/courses/deleteCourse?id=${id}`);
    return res;
}

export const addQuizQuestionsApi = async (values) => {
    const res = await postApiWithToken(`/admin/courses/addQuizQuestions`,values)
    return res;
}

export const getQuizDetailsByCourseIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/courses/getQuizDetailsByCourseId?courseId=${id}`)
    return res;
}

export const deleteCourseChapterByIdApi = async (id) => {
    const res = await deleteApi(`/admin/courses/deleteCourseChapter?id=${id}`)
    return res;
}

export const updateQuizQuestionsApi = async(values) => {
    const res = await putApiWithToken(`/admin/courses/updateQuizQuestions`,values);
    return res;
}

export const UpdateQuiesQuestionOrder = async (values) => {
    const res = await putApiWithToken(`/admin/courses/updateQuizQuestionsOrder`, values);
    return res;
}

export const deleteQuizQuestionsApi = async (id) => {
    const res = await deleteApi(`/admin/courses/deleteQuizQuestions?id=${id}`)
    return res;
}

export const getAllAddedCarouselCourseApi = async (limit = "", page = "", search = "") => {
    return await getApiWithToken(`/admin/courses/getAllAddedCarouselCourse?search=${search}&page=${page}&limit=${limit}`);
}

export const getAllUnCarouselCourseApi = async (search = "", type = "") => {
    return await getApiWithToken(`/admin/courses/getAllUnCarouselCourse?search=${search}&type=${type}`);
}

export const addCourseToCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/courses/addCourseToCarousel`, payload)
}

export const updateCourseCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/courses/updateCourseCarousel`, payload)
}

export const removeCourseFromCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/courses/removeCourseFromCarousel`, payload)
}

export const getAllShopsByVendorApi = async (limit, page, search = "", vendorId = "") => {
    const res = await getApiWithToken(`/admin/shops/getAllShopsByVendor?search=${search}&page=${page}&limit=${limit}&vendorId=${vendorId}`)
    return res;
}

export const createShopApi = async (values) => {
    const res = await postApiWithToken(`/admin/shops/createShop`,values)
    return res
}

export const updateShopDetailsApi = async (values) => {
    const res = await putApiWithToken(`/admin/shops/updateShopDetails`, values)
    return res
}

export const deleteShopByIdApi = async (id) => {
    const res = await deleteApi(`/admin/shops/deleteShopById?id=${id}`)
    return res
}

export const getDetailsByShopIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/shops/getDetailsByShopId?id=${id}`)
    return res;
}

export const getAllProductsByShopIdApi = async (limit, page, search = "", vendorId="") => {
    const res = await getApiWithToken(`/admin/products/getAllProductsByShopId?search=${search}&page=${page}&limit=${limit}&vendorId=${vendorId}`)
    return res
}

export const getAllCategoryAndSubCategoryApi = async () => {
    const res = await getApiWithToken(`/admin/products/getAllCategoryAndSubCategory`);
    return res;
}

export const getAllCategoryApi = async (type = "") => {
    const res = await getApiWithToken(`/admin/products/getAllCategory?type=${type}`);
    return res;
}
export const getSubCategoryByCategoryIdApi = async (categoryId) => {
    const res = await getApiWithToken(`/admin/products/getSubCategoryByCategoryId?categoryId=${categoryId}`);
    return res;
}

export const createOrUpdateCategoryApi = async (payload) => {
    const res = await postApiWithToken(`/admin/products/createOrUpdateCategory`, payload);
    return res;
}

export const createOrUpdateSubCategoryApi = async (payload) => {
    const res = await postApiWithToken(`/admin/products/createOrUpdateSubCategory`, payload);
    return res;
}

export const getAllAddedCarouselCategoryApi = async ( search = "", type="") => {
    return await getApiWithToken(`/admin/products/getAllAddedCarouselCategory?search=${search}&type=${type}`);
}

export const getAllUnCarouselCategoryApi = async (search = "", type = "") => {
    return await getApiWithToken(`/admin/products/getAllUnCarouselCategory?search=${search}&type=${type}`);
}

export const addCategoryToCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/products/addCategoryToCarousel`, payload)
}

export const updateCategoryCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/products/updateCategoryCarousel`, payload)
}

export const removeCategoryFromCarouselApi = async (payload) => {
    return await putApiWithToken(`/admin/products/removeCategoryFromCarousel`, payload)
}


export const updateProductstatusByIdApi = async (values) => {
    const res = await putApiWithToken(`/admin/products/updateProductstatusById`, values)
    return res;
}

export const deleteProductByIdApi = async (id) => {
    const res = await deleteApi(`/admin/products/deleteProductById?id=${id}`)
    return res;
}

export const createProductApi = async(values) => {
    const res = await postApiWithToken(`/admin/products/createProduct`,values)
    return res;
}

export const updateProductDetailsApi = async (values) => {
    const res = await putApiWithToken(`/admin/products/updateProductDetails`,values)
    return res;
}

export const addproductSizeApi = async (values) => {
    const res = await postApiWithToken(`/admin/products/addproductSize`,values)
    return res;
}

export const getProductSizeApi = async () => {
    const res = await getApiWithToken(`/admin/products/getProductSize`)
    return res;
}

export const getAllVendorDetailsApi = async (search="", page, limit) => {
    const res = await getApiWithToken(`/admin/getAllVendorDetails?search=${search}&page=${page}&limit=${limit}`)
    return res;
}

export const getAllAdminUsersApi = async (search="", page, limit) => {
    const res = await getApiWithToken(`/admin/getAllAdminUsers?search=${search}&page=${page}&limit=${limit}`)
    return res;
}

export const getAllNotificationsApi = async (search="", page="", limit="") => {
    const res = await getApiWithToken(`/admin/notification/getAllNotifications?search=${search}&page=${page}&limit=${limit}`)
    return res;
}
export const sendToAllUserByIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/notification/sendToAllUserById?_id=${id}`)
    return res;
}

export const getVendorDetailsByIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/getVendorDetailsById?id=${id}`)
    return res;
}

export const mapTypesApi = async () => {
    const res = await getApiWithToken(`/admin/map/mapTypes`)
    return res;
}

export const removeMapCategoryApi = async (payload) => {
    const res = await putApiWithToken(`/admin/map/removeMapCategory`,payload)
    return res;
}

export const getUserByIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/getUserById?_id=${id}`)
    return res;
}

export const removeBlockOrUnBlockUserApi = async (values) => {
    const res = await putApiWithToken(`/admin/removeBlockOrUnBlockUser`, values)
    return res;
}

export const updateUserByIdApi = async (values) => {
    const res = await putApiWithToken(`/admin/updateUserById`, values)
    return res;
}

export const addOrUpdateUserDocumentsApi = async (values) => {
    const res = await postApiWithToken(`/admin/addOrUpdateUserDocuments`, values)
    return res;
}

export const removeUserDocumentsApi = async ( values ) => {
    const res = await putApiWithToken(`/admin/removeUserDocuments`, values)
    return res;
}

export const getAllDocumentCategoryApi = async () => {
    const res = await getApiWithToken(`/admin/documents/getAllDocumentCategory`)
    return res;
}

export const getUsersDocumentByCategoryIdApi = async (userId="",categoryId="") => {
    const res = await getApiWithToken(`/admin/documents/getUsersDocumentByCategoryId?userId=${userId}&categoryId=${categoryId}`)
    return res;
}

export const uploadAndUpdateUsersDocumentApi = async (values) => {
    const res = await postApiWithToken(`/admin/documents/uploadAndUpdateUsersDocument`, values)
    return res;
}

export const getAddressWithCordinates = async (lat, lng) => {
  
    const res = getApiWithToken(`/admin/map/getAddressByCoordinates?lat=${lat}&lng=${lng}`)
    return res
}

export const getAllOrdersApi = async (type,limit="",page="") => {
    const res = await getApiWithToken(`/admin/orders/getAllOrders?type=${type}&limit=${limit}&page=${page}`)
    return res;
}

export const updateOrderStatusByIdApi = async (values) => {
    const res = await putApiWithToken(`/admin/orders/updateOrderStatusById`, values)
    return res;
}

export const getOrderByIdApi = async (id="") => {
    const res = await getApiWithToken(`/admin/orders/getOrderById?_id=${id}`)
    return res;
}

export const getPageByNameApi = async (name="") => {
    const res = await getApiWithToken(`/admin/pages/getPageByName?name=${name}`)
    return res;
}

export const addOrUpdatePageByNameApi = async (values) => {
    const res = await postApiWithToken(`/admin/pages/addOrUpdatePageByName`, values)
    return res;
}

export const getUserAndVendorApi = async (type = "",to="", form="") => {
    const res = await getApiWithToken(`/admin/reports/getUserAndVendor?type=${type}&to=${to}&from=${form}`)
    return res;
}


export const getAllTransfersApi = async (search="",limit=10,page=1) => {
    const res = await getApiWithToken(`/admin/moneyTransfer/getAllMoneyTransfer?page=${page}&limit=${limit}&search=${search}`)
    return res;
}

export const getMoneyTransferById = async (id="") => {
    const res = await getApiWithToken(`/admin/moneyTransfer/getMoneyTransferById?_id=${id}`)
    return res;
}

export const updateTransferStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/moneyTransfer/updateMoneyTransferStatus`,data)
    return res;
}

export const getBhatKyatExchangeRateApi = async () => {
    const res = await getApiWithToken(`/admin/moneyTransfer/getBhatKyatExchangeRate`)
    return res;
}

export const updateBhatKyatExchangeRateApi = async (data) => {
    const res = await putApiWithToken(`/admin/moneyTransfer/updateBhatKyatExchangeRate`,data)
    return res;
}

export const getAllMovieApi = async (search="",page="",limit="") => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getAllMovies?search=${search}&page=${page}&limit=${limit}`)
    return res;
}

export const createOrUpdateMovieApi = async (data) => {
    const res = await postApiWithToken(`/admin/movieTicketRoute/createOrUpdateMovie`,data)
    return res;
}

export const getMovieByIdApi = async (id="") => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getMovieById?_id=${id}`)
    return res;
}


export const getAllMovieLanguageApi = async (search="",) => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getAllMovieLanguage?search=${search}`)
    return res;
}

export const getAllMovieCinemaApi = async (search="",) => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getAllMovieCinema`)
    return res;
}
export const createOrUpdateMovieLanguageApi = async (data) => {
    const res = await postApiWithToken(`/admin/movieTicketRoute/createOrUpdateMovieLanguage`,data)
    return res;
}

export const getMovieShowApi = async (search="",page="",limit="",movieId="") => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getAllMovieShow?search=${search}&page=${page}&limit=${limit}&movieId=${movieId}`)
    return res;
}

export const createOrUpdateMovieShowApi = async (data) => {
    const res = await postApiWithToken(`/admin/movieTicketRoute/createOrUpdateMovieShow`,data)
    return res;
}

export const getAllMovieTicketsApi = async (search="",page="",limit="") => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getAllMovieTicket?search=${search}&page=${page}&limit=${limit}`)
    return res;
}

export const getMovieTicketByIdApi = async (movieId="") => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getMovieTicketById?_id=${movieId}`)
    return res;
}

export const updateMovieTicketStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/movieTicketRoute/updateMovieTicketStatus`,data)
    return res;
}

export const getAllMovieCinemaPGApi = async (search="",page="",limit="") => {
    const res = await getApiWithToken(`/admin/movieTicketRoute/getAllMovieCinema?search=${search}&page=${page}&limit=${limit}`)
    return res;
}

export const createOrUpdateMovieCinemaApi = async (data) => {
    const res = await postApiWithToken(`/admin/movieTicketRoute/createOrUpdateMovieCinema`,data)
    return res;
}

export const  removeCinemaApi = async (id) => {
    const res = await putApiWithToken(`/admin/movieTicketRoute/removeMovieCinema?_id=${id}`)
    return res;
}

export const  removeMovieShowApi = async (id) => {
    const res = await putApiWithToken(`/admin/movieTicketRoute/removeMovieShow?_id=${id}`)
    return res;
}

export const  removeMovieApi = async (id) => {
    const res = await putApiWithToken(`/admin/movieTicketRoute/removeMovie?_id=${id}`)
    return res;
}

export const getAllBusTicketPGApi = async (search="",page="",limit="") => {
    const res = await getApiWithToken(`/admin/tickets/getUserTicketsOrById?search=${search}&page=${page}&limit=${limit}`)
    return res;
}
export const getBusTicketByIdApi = async (id="") => {
    const res = await getApiWithToken(`/admin/tickets/getUserTicketsOrById?ticketId=${id}`)
    return res;
}

export const updateBusTicketStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/tickets/updateBusTicketStatus`,data)
    return res;
}
export const getAllBusRoutesPGApi = async (search="",page="",limit="") => {
    const res = await getApiWithToken(`/admin/tickets/getAllBusRoute?search=${search}&page=${page}&limit=${limit}`)
    return res;
}

export const  removeBusRouteApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeBusRouteById?_id=${id}`)
    return res;
}

export const createOrUpdateBusRouteApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/createOrUpdateBusRoute`,data)
    return res;
}

export const getAllBusOperatorApi = async (search="",) => {
    const res = await getApiWithToken(`/admin/tickets/getBusOperator?search=${search}`)
    return res;
}

export const getAllBusOperatorPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getBusOperator?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const getBusRouteByIdApi = async (id="") => {
    const res = await getApiWithToken(`/admin/tickets/getBusRouteById?id=${id}`)
    return res;
}

export const  removeBusOperatorApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeBusOperatorById?_id=${id}`)
    return res;
}


export const createOrUpdateBusOperatorApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/createOrUpdateBusOperator`,data)
    return res;
}

export const getAllHotelBookingPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/hotel/getAllUsersBookedRoom?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const updateHotelBookingStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/hotel/updateBookedRoomStatus`,data)
    return res;
}

export const getAllHotelPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/hotel/getAllHotelsOrById?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const getHotelByIdApi = async (id) => {
    const res = await getApiWithToken(`/admin/hotel/getAllHotelsOrById?hotelId=${id}`,)
    return res;
}

export const  removeHotelByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/hotel/removeHotelById?_id=${id}`)
    return res;
}


export const createOrUpdateHotelApi = async (data) => {
    const res = await postApiWithToken(`/admin/hotel/createOrUpdateHotel`,data)
    return res;
}

export const createOrUpdateHotelRoomApi = async (data) => {
    const res = await postApiWithToken(`/admin/hotel/addOrUpdateHotelRoom`,data)
    return res;
}

export const  removeHotelRoomByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/hotel/removeHotelRoomById?_id=${id}`)
    return res;
}

export const getAllHotelRoomPGApi = async (id,search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/hotel/getAllHotelRoomsOrById?hotelId=${id}&search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const getHotelRoomByIdApi = async (hotelId,roomId) => {
    const res = await getApiWithToken(`/admin/hotel/getAllHotelRoomsOrById?hotelId=${hotelId}&roomId=${roomId}`,)
    return res;
}

export const getAllFlightBookingPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getAllFlightBooking?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const updateFlightBookingStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/tickets/updateFlightBookingStatus`,data)
    return res;
}

export const getFlightBookingApi = async (id) => {
    const res = await getApiWithToken(`/admin/tickets/getFlightTicket?_id=${id}`,)
    return res;
}

export const getAllTrainTicketPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getUsersTrainTicket?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const updateTrainTicketStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/tickets/updateTrainTicketStatus`,data)
    return res;
}

export const getAllTrainPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getAllTrain?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const createOrUpdateTrainApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/createOrUpdateTrain`,data)
    return res;
}


export const  removeTrainByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeTrainById?_id=${id}`)
    return res;
}

export const getAllTrainCoachPGApi = async (id="",search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getAllTrainCoach?trainId=${id}&search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const createOrUpdateTrainCoachApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/createOrUpdateTrainCoach`,data)
    return res;
}


export const  removeTrainCoachByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeTrainCoachById?_id=${id}`)
    return res;
}

export const getAllTrainStationPGApi = async (search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getAllStations?search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const createOrUpdateTrainStationApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/createOrUpdateStation`,data)
    return res;
}


export const  removeTrainStationByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeStationById?_id=${id}`)
    return res;
}

export const getAllTrainStopPGApi = async (id="",search="",page=1,limit=10) => {
    const res = await getApiWithToken(`/admin/tickets/getAllTrainStopsByTrainId?trainId=${id}&search=${search}&page=${page}&limit=${limit}`,)
    return res;
}

export const createOrUpdateTrainStopApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/createOrUpdateTrainStop`,data)
    return res;
}

export const updateTrainStopSequenceApi = async (data) => {
    const res = await putApiWithToken(`/admin/tickets/updateTrainStopSequence`,data)
    return res;
}


export const  removeTrainStopByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeTrainStopById?_id=${id}`)
    return res;
}

export const getAllTrainStationApi = async () => {
    const res = await getApiWithToken(`/admin/tickets/getAllStations`,)
    return res;
}


//  ticket banner
export const getAllTicketBannerApi = async (type) => {
    const res = await getApiWithToken(`/admin/tickets/getAllTicketBanner?type=${type}`)
    return res;
}

export const AddTicketBannerApi = async (data) => {
    const res = await postApiWithToken(`/admin/tickets/addTicketBanner`,data)
    return res;
}

export const  removeTicketBannerByIdApi = async (id) => {
    const res = await putApiWithToken(`/admin/tickets/removeTicketBanner?_id=${id}`)
    return res;
}
export const updateTicketBannerApi = async (data) => {
    const res = await putApiWithToken(`/admin/tickets/updateTicketBanner`,data)
    return res;
}
