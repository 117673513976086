import { useFormik } from 'formik';
import JoditEditor from 'jodit-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import Sidebar from '../../../Layout/Sidebar';
import { addCoursesApi, updateCourseApi, uploadImage } from '../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../utils/BaseUrl';
import Header from '../../common/Header';
import Spinwheel from '../../common/Spinwheel';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { courseInitialValues, courseValidationSchema, entertainmentInitialValues, entertainmentValidationSchema } from '../../common/Validation';

function AddCourse() {
  const { adminDetail } = useAdminContext();
  const navigate = useNavigate()
  const [addSpin, setAddSpin] = useState(false)
  const [videoUpload, setVideoUpload] = useState(false);
  const location = useLocation();
  const [fileTypeError, setFileTypeError] = useState({ image: "", video: "" })
  const intialValues = {}
  let path = location.pathname
  path = path.split("/").slice(-1)[0];
  path = (path[0].toUpperCase() + path.slice(1))
  if ((path === "Update") && location.state == null) {
    navigate("/entertainment/add");
  }
  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      readonly: false,
      placeholder: 'Start writing...',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true

    }
  }, []
  );
  if (location.state) {
    intialValues.id = location.state?.data?._id
    intialValues.courseTitle = location.state?.data?.courseTitle
    intialValues.publisher = location.state?.data?.publisher
    intialValues.isBanner = location.state?.data?.isBanner
    intialValues.isPopular = location.state?.data?.isPopular
    intialValues.courseThumbnail = location.state?.data?.courseThumbnail
    intialValues.content = location.state?.data?.content
    intialValues.isPublished = location.state?.data?.isPublished
    intialValues.creditPoints = location.state?.data?.creditPoints
    intialValues.completionTime = location.state?.data?.completionTime
    intialValues.publishDate = location.state?.data?.publishDate
    intialValues.price = location.state?.data?.price
    intialValues.requirePer = location.state?.data?.requirePer
  }
  const handleSubmit = async (values) => {
    try {
      let res
      if (path === "Add") {
        setAddSpin(true)
        res = await addCoursesApi(values)
      } else if (path === "Update") {
        setAddSpin(true)
        res = await updateCourseApi(values);
      }
      if (res?.status === 200) {
        toast.success(res.data?.message)
        navigate("/course")
      }
      setAddSpin(false)
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddSpin(false)
    }
  }

  const handleVideo = async (e) => {
    setVideoUpload(true)
    const file = e.target.files[0];
    const types = ['video/mp4', 'video/avi', 'video/mov', 'video/webm'];
    if (file) {
      if (types.includes(file.type)) {
        setFileTypeError({ ...fileTypeError, video: "" });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 4)
        formData.append("folder", "course")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue("video", res.data?.data);
          toast.success("File uploaded");
          setVideoUpload(false);
        }
      } else {
        formik.setFieldValue("video", "");
        setFileTypeError({ ...fileTypeError, video: "Must be video only" })
        setVideoUpload(false)
      }
    }
  }



  const handleThumbnail = async (e) => {
    const file = e.target.files[0];
    const types = ['image/jpeg', 'image/png', 'image/gif']
    setFileTypeError({ ...fileTypeError, image: "" });
    if (file) {
      if (types.includes(file.type)) {
        setFileTypeError({ ...fileTypeError, image: "" });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 3);
        formData.append("folder", "course");
        const res = await uploadImage('/users/fileUpload', formData)
        formik.setFieldTouched(`courseThumbnail`, true)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue("courseThumbnail", res.data?.data?.fileName);
          toast.success("File uploaded");
        }
      } else {
        formik.setFieldTouched(`courseThumbnail`, true)
        formik.setFieldValue("courseThumbnail", "");
        setFileTypeError({ ...fileTypeError, image: "Must be image only" });
      }
    }

  }

  const formik = useFormik({
    initialValues: location.state ? intialValues : courseInitialValues,
    onSubmit: handleSubmit,
    validationSchema: courseValidationSchema,
  });


  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Study" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/course" className="breadcrumb-item">
                    Course
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {path}-course
                </li>
              </ol>
            </nav>
            <div>
              <h2 className="report">Course details</h2>
            </div>
            <hr />
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" onSubmit={formik.handleSubmit} >
                <div className='row row-cols-lg-3 row-cols-md-2'>
                  <div>
                    <label htmlFor="title" className="form-label">Course title</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="title" placeholder="Enter title" name="courseTitle" value={formik.values.courseTitle} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                        <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                      </svg>
                      {formik.touched.courseTitle && formik.errors.courseTitle ? (<div className='req-error'>{formik.errors.courseTitle}</div>) : null}
                    </div>
                    <br />
                  </div>
                  {/* <label htmlFor="publishDate" className="form-label">Published Date</label>
                <div className="position-relative">
                  <ReactDatePicker
                    selected={formik.values.publishDate ? new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY").format()) : ""}
                    name='publishDate'
                    onChange={(e) => {
                      e ?
                        formik.setFieldValue("publishDate", moment(e).format("MM-DD-YYYY")) :
                        formik.setFieldValue("publishDate", "")
                    }}
                    onBlur={formik.handleBlur}
                    className='form-control w-auto'
                    isClearable={formik.values.publishDate === "" ? false : true}
                    placeholderText='Pickup date'
                    minDate={path === "Add" ? new Date() : new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY")) < new Date() ? new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY")) : new Date()} 
                    autoComplete="off" 
                  />
                  {formik.touched.publishDate && formik.errors.publishDate ? (<div className='req-error'>{formik.errors.publishDate}</div>) : null}
                </div>
                <br /> */}
                  <div>
                    <label htmlFor="publisher" className="form-label">Creator name</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="publisher" placeholder="Enter creator name" name="publisher" value={formik.values.publisher} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.publisher && formik.errors.publisher ? (<div className='req-error'>{formik.errors.publisher}</div>) : null}
                    </div>
                    <br />
                  </div>


                  <div>
                    <label htmlFor="publishDate" className="form-label">Published date</label>
                    <div className="position-relative">
                      <ReactDatePicker
                        selected={formik.values.publishDate ? new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY").format()) : ""}
                        name='publishDate'
                        id="publishDate"
                        onChange={(e) => {
                          e ?
                            formik.setFieldValue("publishDate", moment(e).format("MM-DD-YYYY")) :
                            formik.setFieldValue("publishDate", "")
                        }}
                        onBlur={formik.handleBlur}
                        className='form-control w-100'
                        // isClearable={formik.values.publishDate === "" ? false : true}
                        placeholderText='Pickup date'
                        minDate={path === "Add" ? new Date() : new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY")) < new Date() ? new Date(moment(formik.values.publishDate?.toString(), "MM-DD-YYYY")) : new Date()}
                        autoComplete="off"
                        dateFormat={"dd/MM/yyyy"}
                      />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar input-icon" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                      </svg>

                      {formik.touched.publishDate && formik.errors.publishDate ? (<div className='req-error'>{formik.errors.publishDate}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="completionTime" className="form-label">Course completion (Min) </label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="completionTime" placeholder="Enter course completion " name="completionTime" value={formik.values.completionTime} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.completionTime && formik.errors.completionTime ? (<div className='req-error'>{formik.errors.completionTime}</div>) : null}
                    </div>

                    <br />
                  </div>

                  <div>
                    <label htmlFor="creditPoints" className="form-label">Credit points</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="creditPoints" placeholder="Enter credit points" name="creditPoints" value={formik.values.creditPoints} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.creditPoints && formik.errors.creditPoints ? (<div className='req-error'>{formik.errors.creditPoints}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="price" className="form-label">Price</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="price" placeholder="Enter price" name="price" value={formik.values.price} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.price && formik.errors.price ? (<div className='req-error'>{formik.errors.price}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="requirePer" className="form-label">Required percentage</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="requirePer" placeholder="Enter percentage" name="requirePer" value={formik.values.requirePer} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.requirePer && formik.errors.requirePer ? (<div className='req-error'>{formik.errors.requirePer}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="courseThumbnail" className="form-label">Thumbnail image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik.values.courseThumbnail ? ImageUrl + formik.values.courseThumbnail : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounded-circle" alt="Selected file" />}
                        {formik.values.type === "video" &&
                          <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik.setFieldValue("courseThumbnail", "undefined/image.svg") }} >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </>
                        }
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="courseThumbnail" className="form-control" name="courseThumbnail" accept="image/*" onChange={handleThumbnail} />
                        Upload
                      </div>
                    </div>
                    {formik.touched.courseThumbnail && (formik.errors.courseThumbnail || fileTypeError.image?.trim()?.length > 0) ? (<div className='req-error'>{fileTypeError.image?.trim()?.length > 0 ? fileTypeError.image : formik.errors.courseThumbnail}</div>) : null}
                    <br />
                  </div>
                  

                  {/* <div>
                    <div className='d-flex justify-content-between '>
                      <label htmlFor="isPublished" className="form-label"> Published/Unpublished</label>
                      <div className="form-check form-switch map-togle-container">
                        <input className="form-check-input" type="checkbox" name='isPublished' role="switch" id="isPublished" checked={formik.values.isPublished} onChange={formik.handleChange} />
                      </div>
                    </div>
                    <br />
                  </div> */}
                
                 
                  <div>
                    <div className='d-flex justify-content-between '>
                      <label htmlFor="isPopular" className="form-label"> Popular </label>
                      <div className="form-check form-switch map-togle-container">
                        <input className="form-check-input" type="checkbox" name='isPopular' role="switch" id="isPopular" checked={formik.values.isPopular} onChange={formik.handleChange} />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                  <div>
                    <label htmlFor="description" className="form-label">Description</label>
                    <div className="position-relative">
                      <JoditEditor
                        ref={editor}
                        config={config}
                        value={formik.values.content}
                        onBlur={(e) => {
                          formik.setFieldTouched("content", true)
                          e === "<p><br></p>" ? formik.setFieldValue("content", "") : formik.setFieldValue("content", e);
                        }}
                      // onChange={(e) => formik.setFieldValue("content", e)}
                      />
                      {formik.touched.content && formik.errors.content ? (<div className='req-error'>{formik.errors.content}</div>) : null}
                    </div>
                    <br />
                  </div>
                  <div className="text-center d-flex justify-content-around">
                    <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                    <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }} >Back</div>
                  </div>
              
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCourse