import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { createOrUpdateNotificationApi, getAllNotificationsApi, sendToAllUserByIdApi } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import toast from "react-hot-toast";
import Pagination from '../../common/Pagination';
import Spinwheel from '../../common/Spinwheel';
import { useFormik } from 'formik';
import moment from 'moment';
import { debounce } from 'lodash';
import { notificationIntialValue, notificationValidationSchema } from '../../common/Validation';
import AddOrUpdateNotification from './AddOrUpdateNotification';
import ConfirmModal from '../../common/ConfirmModal';

function Notifications() {
  const [tableSpin, setTableSpin] = useState(true);
  const [search, setSearch] = useState("");
  const [tabelData, setTableData] = useState([]);
  const limit = 10;
  const addOrUpdateNotification = "addOrUpdateNotificationModalId";
  const confirmSendModalId = "confirmSendModalId"
  const [submitSpin, setSubmitSpin] = useState(false)
  const [page, setPage] = useState(0);
  let [selectedPage, setSelectPage] = useState(0);
  const [selectItem, setSelectItem] = useState()


  const getNotificationList = async (search="") => {
    try {
      setTableSpin(false)
      const res = await getAllNotificationsApi(search, selectedPage + 1, limit)
      if (res.status === 200) {
        setTableData(res.data?.data?.notifications)
        const totaldata = res.data?.data?.totalData
        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }

  const sendNotficationHandler = async () => {
    try {
      setSubmitSpin(true)
      const res = await sendToAllUserByIdApi(selectItem?._id)
      if(res.status === 200) {
        document.getElementById(confirmSendModalId).click();
        toast.success(res.data.message)
        getNotificationList("")
      }
    } catch (error) {
      if (error?.response) toast.error(error?.response?.data?.message);
      else toast.error("Something went wrong");
    }
    setSubmitSpin(false)
  }



  const notificationformik = useFormik({
    initialValues: notificationIntialValue,
    onSubmit: async (values) => {
      try {
        setSubmitSpin(true)
        const res = await createOrUpdateNotificationApi(values)
        if (res.status === 200) {
          toast.success(res.data.message)
          getNotificationList("")
          document.getElementById(addOrUpdateNotification).click();
        }
      } catch (error) {
        if (error?.response) toast.error(error?.response?.data?.message);
        else toast.error("Something went wrong");
      }
      setSubmitSpin(false)
    },
    validationSchema: notificationValidationSchema
  })



  const onChange = async (e) => {
    let search = e.target.value;
    setSearch(search)
    getNotificationList(search)

  };
  const debouncedOnChange = debounce(onChange, 500);

  const handlePageClick = (event) => {
    setSelectPage(event.selected);

    selectedPage = event.selected
    getNotificationList(search);
  };

  useEffect(() => {
    getNotificationList("")
  }, [])

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Notifactions" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Notifactions
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className="btn-custom w-auto" data-bs-toggle="modal" data-bs-target={`#${addOrUpdateNotification}`} onClick={() => { notificationformik.resetForm(); }} >Add</div>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>

              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        title
                      </span>
                    </th>

                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        subtitle
                      </span>
                    </th>

                    <th colSpan={2}>
                      <span
                        className="list-sort text-muted"
                      >
                        status
                      </span>
                    </th>

                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {tabelData?.length > 0 ? tabelData?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td>
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.title ? ele?.title : "-"}
                            </span>
                          </td>

                        

                          <td >
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.subtitle ? ele?.subtitle : "-"}
                            </span>

                          </td>
                          <td >
                            <span
                              className="item-phone text-reset"
                            >
                              <div className={`badge ${ele?.isSent ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                                {ele?.isSent ? "Sent" : "Unsent"}
                              </div>
                            </span>

                          </td>

                          <td className="text-end">
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  style={{cursor:"pointer"}}
                                  data-bs-target={`#${addOrUpdateNotification}`}
                                  onClick={() => {
                                    notificationformik.setValues(ele)}}>
                                  Edit
                                </div>
                                {!ele?.isSent && <div data-bs-toggle="modal" style={{ cursor: "pointer" }} data-bs-target={`#${confirmSendModalId}`} className="dropdown-item" onClick={() => { setSelectItem(ele); }}  >
                                  Send
                                </div>}
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>

            </div>
          </div>
          <div className="d-flex justify-content-center">
            {tabelData?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
              : null}
          </div>

        </div>
      </div>
      <AddOrUpdateNotification formik={notificationformik} modalId={addOrUpdateNotification} addSpin={submitSpin} />
      <ConfirmModal modalId={confirmSendModalId} message={<>Are you sure you want to<br /> send this?</>} spin={submitSpin} confirmHandler={sendNotficationHandler}/>
    </>
  )
}

export default Notifications