import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import {getAllTransfersApi,updateTransferStatusApi,getBhatKyatExchangeRateApi,updateBhatKyatExchangeRateApi,  } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import toast from "react-hot-toast";
import Pagination from '../../common/Pagination';
import Spinwheel from '../../common/Spinwheel';
import moment from 'moment';
import ViewTransactionModel from './ViewTransactionModel';

function Transfer() {
  const [tableSpin, setTableSpin] = useState(true);
  const [transfers, setTransfers] = useState([]);
  const [markTransfer,setMarkTransfer] = useState({status:false,_id:null})
  const [selectRow, setSelectRow] = useState({})
  const [exchangeRate,setExchangeRate] = useState({_id:null,value:0,loading:false,isEdit:false,saving:false})
  let [selectedPage, setSelectPage] = useState(0);
  const [page, setPage] = useState(0);
  const limit = 10;
  const transferModalId = "transferModalId"
  const viewModelId = "viewModelId"
  

  const getTransferList = async (selectedPage) => {
    try {
      setTableSpin(false)
      const res = await getAllTransfersApi("",limit,selectedPage+1)
      if (res.status === 200) {
        setTransfers(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData
       
        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }


const handleConfirmation = async()=>{
    setMarkTransfer({...markTransfer,status:true})
    try {
      const res = await updateTransferStatusApi({_id:markTransfer?._id,status:"transferred"})
      if(res.status==200){
          getTransferList(selectedPage)
        toast.success(res.data?.message);
      }
      setMarkTransfer({_id:null,status:false})
      document.getElementById(transferModalId).click()
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Failed to update status")
      setMarkTransfer({_id:null,status:false})
      document.getElementById(transferModalId).click()
    }

}

const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getTransferList(event.selected)
  };

const handleExchangeRate = (e)=>{
  const value =e?.target?.value
  const regex = /^[0-9\s.]*$/;
  if(regex?.test(value)){
    setExchangeRate({...exchangeRate,value:value?.trim()})
  } else if (value === "") {
    setExchangeRate({ ...exchangeRate, value: "" });
  }
}

const getExchangeRate = async () => {
  try {
    setExchangeRate({...exchangeRate,loading:true});
    const res = await getBhatKyatExchangeRateApi()
    if (res.status === 200) {
      const {exchangeValue,_id} = res?.data?.data
      const value = exchangeValue || 0
      setExchangeRate({...exchangeRate,loading:false,value,_id});
    }
  } catch (error) {
    setExchangeRate({...exchangeRate,loading:false});
  }
}

useEffect(() => {
  getTransferList(selectedPage)
  getExchangeRate()
}, [])

const updateExchangeRate = async()=>{
  try {
    setExchangeRate({...exchangeRate,saving:true})
    const payload ={
      _id:exchangeRate?._id,
      exchangeValue:exchangeRate?.value
    }
    const res = await updateBhatKyatExchangeRateApi(payload)
    if (res.status === 200) {
      setExchangeRate({...exchangeRate,saving:false,isEdit:false});
      toast.success(res.data?.message);
    }
  } catch (error) {
    setExchangeRate({...exchangeRate,saving:false,isEdit:false});
    toast.success(error.response?.data?.message || "Failed to update");
  }
}

const handleSaveExchangeRate =async()=>{
  if(exchangeRate.isEdit){
    updateExchangeRate()
  }else{
    setExchangeRate({...exchangeRate,isEdit:true})
  }
}

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Transfers" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Transfers
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                {exchangeRate?.loading ? <Spinwheel /> : <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <div>
                    <label htmlFor="1 Baht" className="form-label me-2">1 Baht =</label>
                    <input
                      className="form-search me-2 w-50"
                      type="input"
                      name=""
                      style={{minWidth:"100px"}}
                      value={exchangeRate?.value}
                      onChange={handleExchangeRate}
                      placeholder="Kyat"
                      disabled={!exchangeRate.isEdit || exchangeRate?.saving}
                    />
                    {/* <label htmlFor="1 Baht" className="form-label me-2">Kyat</label> */}
                  </div>
                  <button disabled={exchangeRate.saving} onClick={handleSaveExchangeRate} className="btn-custom w-auto" >{exchangeRate?.saving ? <Spinwheel sizeClass="spin-small" /> : (exchangeRate?.isEdit ? "Save" : "Edit")} </button>
                  <br />
                </div>}
        
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                       From
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                      To
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                      Account
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                      Bank
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Amount(from)
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        To (currency)
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Amount (to)
                      </span>
                    </th>

                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                       Paid On
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                       Received On
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                       Remark
                      </span>
                    </th>
                    <th colSpan={2}>
                      <span
                        className="list-sort text-muted"
                      >
                        status
                      </span>
                    </th>
                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {transfers?.length > 0 ? transfers?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td>
                            <Link
                              className="item-name text-reset"
                              to={`/users/${ele?.userId?._id}`}
                            >
                              {ele?.userId?.name ? ele?.userId?.name : "-"}
                            </Link>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.name ? ele?.name : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.accountNo ? ele?.accountNo : "-"}
                            </span>
                          </td>
                         
                          <td className='position-relative'>
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.bankName ? (
                                <div className="tooltip1">
                                  {ele?.bankName?.substr(0, 20)}...
                                  <div className='wrapper'>
                                    <div className="tooltip1-text">{ele?.bankName}</div>
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.currencyAmt ? ele?.currencyAmt : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.exchangeTo ? ele?.exchangeTo : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.exchangeAmt ? ele?.exchangeAmt : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.paidOn ? moment(ele?.paidOn).format("DD/MM/YYYY") : "-"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="item-phone text-reset"
                            >
                              {ele?.receivedOn ? moment(ele?.receivedOn).format("DD/MM/YYYY") : "-"}
                            </span>
                          </td>
                          <td className='position-relative'>
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.remark ? (
                                <div className="tooltip1">
                                  {ele?.remark?.substr(0, 20)}...
                                  <div className='wrapper'>
                                    <div className="tooltip1-text">{ele?.remark}</div>
                                  </div>
                                </div>
                              ) : (
                                "-"
                              )}
                            </span>                           
                          </td>
                          <td>                           
                            <span
                              className="item-phone text-reset"
                            >
                              <div className={`badge ${ele?.status == "transferred" ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                                {ele?.status}
                              </div>
                            </span>
                          </td>


                          <td className="text-end">
                            
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu ">
                                <div data-bs-toggle="modal" data-bs-target={`#${viewModelId}`} className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setSelectRow(ele); }}  >
                                  View
                                </div>

                                {ele?.status?.toLowerCase() != "transferred" &&
                                  <div data-bs-toggle="modal" data-bs-target={`#${transferModalId}`} className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setMarkTransfer({ ...markTransfer, _id: ele?._id }); }}  >
                                    Mark as transferred
                                  </div>
                                }
                              </div>
                            </div>
                            
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
              <div className="d-flex justify-content-center">
            {transfers?.length > 0 ? <Pagination
              clickHandler={handlePageClick}
              page={page}
              selectedPage={selectedPage}
            />
              : null}
          </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id={transferModalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pe-0 ps-0 block-user">
                            <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
                            <h1 className="mb-0">Are you sure you want to<br /> mark as transferred ?</h1>
                        </div>
                        <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
                            <button type="button" className="btn-custom w-auto" disabled={markTransfer?.status} onClick={handleConfirmation} >YES</button>
                            <button type="button" className="btn-custom w-auto mt-2" disabled={markTransfer?.status} data-bs-dismiss={`${markTransfer?.status ? "" : "modal"}`} >
                                NO
                            </button>
                        </div>
                    </div>
                </div>
      </div>

      <ViewTransactionModel modalId={viewModelId} id={markTransfer?._id} data={selectRow} />
    </>
  )
}

export default Transfer