import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Category from './modal/Category';
import { categoryInitialValue, categoryValidationSchema, subCategoryInitialValue, subCategoryValidationSchema } from '../../../common/Validation';
import Spinwheel from '../../../common/Spinwheel';
import Header from '../../../common/Header';
import { Link, useNavigate } from 'react-router-dom';
import { createOrUpdateCategoryApi, createOrUpdateSubCategoryApi, getAllCategoryAndSubCategoryApi, getAllCategoryApi, getSubCategoryByCategoryIdApi } from './../../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { ImageUrl } from './../../../../utils/BaseUrl';

function NewsCategory() {
  const [loader, setLoader] = useState(true)
  const [categories, setCategories] = useState([]) 
  const categoryModal = "categoryModalID"
  const [submitLoader,setSubmitLoader] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const navigate = useNavigate();


  const categoryFormik = useFormik({
    initialValues: categoryInitialValue,
    onSubmit: async (values) => {
      try {
        const payload = {...values, type: "news"}
        setSubmitLoader(true)
        const res = await createOrUpdateCategoryApi(payload)
        if(res.status === 200) {
          toast.success(res.data?.message)
          
          document.getElementById(categoryModal).click()
          getAllCategories();
        }
        
      } catch (error) {
        console.log(error)
        toast.error("Something went wrong.")
      }

      setSubmitLoader(false)
    },
    validationSchema: categoryValidationSchema,
  })
  
  const getAllCategories = async (type) => {
    try {
      setLoader(true)
      const res = await getAllCategoryApi( type = "news")
      if (res.status === 200) {
        // console.log(res.data?.data)
        setCategories(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }


  

  useEffect(() => {
    getAllCategories();

  }, [])

  return (
    <>
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Category" />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/news">
                  Info &amp; news
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Category
                </li>
              </ol>
            </nav>
          </div>
          <div className="mb-4" style={{ textAlign: "end" }}>
            <div style={{ display: "-webkit-inline-box" }}>

              <div className="btn-custom w-auto" onClick={() => { navigate("carousel") }}>
                Manage carousel
              </div>

              <div className="ms-2"><button className="btn-custom" data-bs-toggle="modal" data-bs-target={`#${categoryModal}`} onClick={() => { categoryFormik.resetForm(); }} >Add</button>
               
              </div>
             
            </div>
          </div>
          <div className="card p-3 table-responsive" >
            <table className="table table-md table-hover card-table">
              <thead>
                <tr>
                  <th>
                    <span
                      className="list-sort text-muted"
                    >
                      S.No
                    </span>
                  </th>
                 

                  <th>
                    <span
                      className="list-sort text-muted"
                    >
                      name
                    </span>
                  </th>

                  <th colSpan={2}>
                    <span
                      className="list-sort text-muted"
                    >
                      Description
                    </span>
                  </th>
                </tr>
              </thead>
              {loader ? (
                <tbody>
                  <tr>
                    <td colSpan={12} className='text-center'>
                      <Spinwheel />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="list fs-base">
                  {categories?.length > 0 ? (
                    categories?.map((ele, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div style={{ cursor: "pointer" }} className='text-primary' onClick={() => {  setSelectedCategory(ele) }}>
                            <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.image && ele?.image !== "null" ? ImageUrl + encodeURIComponent(ele?.image) : "/images/person-circle.svg"}
                                alt="..."
                              />
                            </div>
                          {ele?.name}
                          </div>
                          </td>
                        <td>{ele?.description}</td>
                        <td>
                          <div className="dropdown text-center">
                            <button
                              className=" border-0 bg-white"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              </svg>
                            </button>
                            <ul
                              className="dropdown-menu"
                              style={{ zIndex: "1000" }}
                            >
                              <li
                                onClick={() => { categoryFormik.setValues({_id: ele?._id, name:ele?.name, image: ele?.image, description: ele?.description}) }}
                                data-bs-toggle="modal"
                                data-bs-target={`#${categoryModal}`}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="dropdown-item" >
                                  Edit
                                </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td align="center" colSpan="12">
                            <h1>Data Not Found</h1>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <Category modalId={categoryModal} formik={categoryFormik} addLoader={submitLoader}  />
    </>
  )
}

export default NewsCategory