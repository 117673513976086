import React, { useState } from 'react'
import { ImageUrl } from '../../../utils/BaseUrl';
import Spinwheel from '../../common/Spinwheel';
import { uploadImage } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';

function AddVendorModal(props) {
  const { formik, modalId, addSpin } = props
  const [eyes, setEye] = useState({ eyeblur: "d-none", slashblur: "" })
  const handleShowPassword = () => {
    const ele = document.getElementById("pass")
    ele.type = ele.type === "password" ? "text" : "password";
    setEye({ eyeblur: eyes.eyeblur === "d-none" ? "" : "d-none", slashblur: eyes.slashblur === "d-none" ? "" : "d-none" })
  }

  const logoChange = async (e) => {
    const file = e.target.files[0];
    const types = ['image/jpeg', 'image/png', 'image/gif']
    if (file) {
      if (types.includes(file?.type)) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", 1)
        formData.append("folder", "admin")
        const res = await uploadImage('/users/fileUpload', formData)
        if (res.status === 200 && res.data?.status === 1) {
          formik.setFieldValue("profileImage", res.data?.data?.fileName)
          toast.success("File uploaded")
        }
      } else {
        formik.setFieldValue("profileImage", "")
        setTimeout(() => {
          toast.error("Must be image only")
        }, 1000)

      }
    }
  }

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body  block-user">
            <form action="" onSubmit={formik.handleSubmit} >
              <div className='d-flex'><label htmlFor="profile" className="form-label"> Logo image </label></div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="position-relative">
                  {<img src={formik.values?.profileImage ? ImageUrl + encodeURIComponent(formik.values?.profileImage) : process.env.PUBLIC_URL + "/images/person-circle.svg"} width="70px" height="70px" className="rounded-circle" alt="Selected file" />}
                  {
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x xmark" viewBox="0 0 16 16" onClick={() => { formik.setFieldValue("logo", "others/person-circle.svg") }} >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </>
                  }
                </div>
                <div className="btn_upload">
                  <input type="file" id="upload_file" accept="image/*" className="form-control" name="profileImage" onChange={logoChange} />
                  Upload
                </div>
              </div>
              <br />
              <div className='d-flex'><label htmlFor="profile" className="form-label"> Name</label></div>
              <div className="position-relative"><input className="form-control border border-1" type="text" id="first" placeholder="Enter your name" name="name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill input-icon" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>
                {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
              </div>
              <br />
              <div className='d-flex'><label htmlFor="profile" className="form-label"> Email </label></div>
              <div className="position-relative"><input className="form-control border border-1" type="email" id="email" placeholder="Enter email" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill  input-icon" viewBox="0 0 16 16">
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                </svg>
                {formik.touched.email && formik.errors.email ? (<div className='req-error'>{formik.errors.email}</div>) : null}
              </div>
              <br />

             { !formik?.values?._id && <>               
                <label
                  className="form-label text-start d-block"
                  htmlFor="pass"
                >
                  Password
                </label>
                <div className="position-relative " >
                  <input
                    className="form-control border border-1 "
                    type="password"
                    name="password"
                    id="pass"
                    placeholder="Enter password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete='off'
                  />
                  <div onClick={handleShowPassword} >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className={`bi bi-eye-fill input-icon ${eyes?.eyeblur}`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className={`bi bi-eye-slash-fill input-icon ${eyes?.slashblur} `}
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  </div>
                  {formik.touched.password && formik.errors.password ? (<div className='req-error'>{formik.errors.password}</div>) : null}
                </div>
                <br />
                <label
                  className="form-label text-start d-block"
                  htmlFor="pass"
                >
                  Role
                </label>
                <select name="role" id="modal" className="form-select form-control border border-1" value={formik.values.role} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                  <option value="admin">Admin</option>
                  <option value="shopVendor">Shoping</option>
                  <option value="ticketVendor">Ticket</option>
                  <option value="jobVendor">Job</option>
                  <option value="newsVendor">News & info</option>
                  <option value="entertainmentVendor">Entertainment</option>
                  <option value="livingVendor">Living</option>
                  {/* <option value="shopVendor">Shoping</option> */}
                </select>
              <br />
              </>}
              <div className="text-center d-flex justify-content-around">
                <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin}>{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                <div className="btn-custom w-auto mt-2" data-bs-dismiss="modal"  >Back</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default AddVendorModal