import React from 'react'
import Spinwheel from './Spinwheel'

function ConfirmModal({ confirmHandler,modalId,message, spin }) {
  
  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pe-0 ps-0 block-user">
            <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
            <h1 className="mb-0">{message}</h1>
          </div>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn-custom w-auto" disabled={spin} onClick={confirmHandler}  >{spin ? <Spinwheel sizeClass="spin-small" /> :"YES"}</button>
            <button type="button" className="btn-custom w-auto mt-2" data-bs-dismiss='modal' >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal