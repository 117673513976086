import React from 'react'
import Spinwheel from '../../common/Spinwheel';


function AddOrUpdateNotification({ formik, modalId, addSpin }) {

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body  block-user">
            <form action="" onSubmit={formik.handleSubmit} >
             
              <div className='d-flex'><label htmlFor="title" className="form-label">Title</label></div>
              <div className="position-relative"><input className="form-control border border-1" type="title" id="title" placeholder="Enter title" name="title" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                {formik.touched.title && formik.errors.title ? (<div className='req-error'>{formik.errors.title}</div>) : null}
              </div>
              <br />
              <div className='d-flex'><label htmlFor="subtitle" className="form-label"> Subtitle </label></div>
              <div className="position-relative"><input className="form-control border border-1" type="subtitle" id="subtitle" placeholder="Enter subtitle" name="subtitle" value={formik.values.subtitle} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                {formik.touched.subtitle && formik.errors.subtitle ? (<div className='req-error'>{formik.errors.subtitle}</div>) : null}
              </div>
              <br />
              

              <div className="text-center d-flex justify-content-around">
                <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin}>{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                <div className="btn-custom w-auto mt-2" data-bs-dismiss="modal"  >Back</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default AddOrUpdateNotification