  import React, { useState } from 'react'
  
  import Spinwheel from '../../../common/Spinwheel'
  import { arrayMove, List } from "react-movable";
  import {  updateCategoryCarouselApi } from '../../../../utils/ApiEndpointFunctions';
  import toast from "react-hot-toast";
import { ImageUrl } from '../../../../utils/BaseUrl';
 

function CarouselTable({ tableData, selectedPage, tableSpin, setRemoveData, limit, setCategoryData, setTableData,  carouselFormik, carouselModalId }) {
    const removeCarouselModalId = "removeCarouselModalId"
    const handleOrderChanged = async ({ oldIndex, newIndex }) => {
      const newArray = arrayMove(tableData, oldIndex, newIndex);
      setTableData(newArray);
      let payload = newArray.map(ele => ({_id: ele._id}));
      try {
        const res = await updateCategoryCarouselApi({ carousel: payload });
        if(res.status === 200) {
          toast.success(res.data?.message)
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong")
      }
    };

    return (
      <>
      <List 
          values={tableData}
          onChange={handleOrderChanged}
          renderList={(item) => {
            return (
             <div className='table-responsive'>
                <table className="table table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          S.No
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted"

                        >
                          name
                        </span>
                      </th>

                      

                      <th colSpan={2}>
                        <span
                          className="list-sort text-muted"

                        >
                          Description
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody {...item?.props} className="list fs-base">
                    {tableSpin ? tableData?.length > 0 ? item?.children : <tr align="center">
                      <td colSpan="12">
                        <h1>
                          Data Not Found
                        </h1>
                      </td>
                    </tr> : <tr align="center">
                      <td colSpan="12">
                        <Spinwheel />
                      </td>
                    </tr>}
                  </tbody>
                </table>
             </div>
            )
          }}

          renderItem={(ele) => {
            
            return (
              <tr {...ele.props} key={ele?.index} style={{ ...ele.props.style, zIndex: undefined }} >
                <td data-movable-handle >{ele?.index + 1}</td>
                <td>
                  <div >
                    <div className="avatar avatar-xs align-middle me-2">
                      <img
                        className="avatar-img rounded-circle"
                        src={ele?.value?.image && ele?.value?.image !== "null" ? ImageUrl + encodeURIComponent(ele?.value?.image) : "/images/person-circle.svg"}
                        alt="..."
                      />
                    </div>
                    {ele?.value?.name}
                  </div>
                </td>
                <td>{ele?.value?.description}</td>
                <td className="text-end">
                  <div className="dropdown dropstart">
                    <span
                      className="dropdown-ellipses"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fe fe-more-vertical" />
                    </span>
                    <div className="dropdown-menu dropdown-menu-end">
                      <>
                        <div className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#${removeCarouselModalId}`} style={{ cursor: "pointer" }} onClick={() => { setRemoveData(ele.value._id) }} >
                          Remove
                        </div>
                      </>
                    </div>
                  </div>
                </td>
              </tr>
            )
          }}
      />
      </>

    )
  }

  export default CarouselTable