import React, { useEffect, useState } from 'react'
import moment from 'moment';


import Spinwheel from '../.././common/Spinwheel';
import { getMoneyTransferById } from '../../../utils/ApiEndpointFunctions';

function ViewTransactionModel({ modalId, id, data }) {
  // const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)


  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <div action="" className='p-4' >
                <h1 className='text-center'>
                  Transaction Details
                </h1>
                {loading ?
                  <Spinwheel /> :
                  <div className="row">
                    
                    <h2 className='fw-bold text-center'>Details</h2>
                    <div className="row row-cols-md-2">
                      <div className="mb-3">
                        <h4 className='fw-bold'>Name (from)</h4>
                        <div className='position-relative'>
                          <p className='mb-0 text-break'>{data?.userId?.name || "-"}</p>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>Name (to)</h4>
                        <div className='position-relative'>
                          <p className='mb-0'>{data?.name || "-"}</p>
                        </div>
                      </div>

                      <div className="mb-3">
                        <h4 className='fw-bold'>Address</h4>
                        <div className='position-relative'>
                          <p className='mb-0'>{data?.address}</p>
                        </div>
                      </div>

                      <div className="mb-3">
                        <h4 className='fw-bold'>Account no</h4>
                        <div className='position-relative'>
                          <p className='mb-0'>{data?.accountNo || "-"}</p>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>Bank name</h4>
                        <div className='position-relative'>
                          <p className='mb-0'>{data?.bankName}</p>
                        </div>
                      </div>
                      
                      <div className="mb-3">
                        <h4 className='fw-bold'>Amount (from)</h4>
                        <p className='text-capitalize mb-0'>{data?.currencyAmt || "-"}</p>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>To (currency)</h4>
                        <p className='text-capitalize mb-0'>{data?.exchangeTo || "-"}</p>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'> Amount (to)</h4>
                        <p className='text-capitalize mb-0'>{data?.exchangeAmt || "-"}</p>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>Paid On</h4>
                        <p className='text-capitalize mb-0'>{data?.paidOn ? moment(data?.paidOn).format("DD/MM/YYYY") : "-"}</p>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>Received On</h4>
                        <p className='text-capitalize mb-0'>{data?.receivedOn ? moment(data?.receivedOn).format("DD/MM/YYYY") : "-"}</p>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>Remark</h4>
                        <p className='text-capitalize mb-0'>{data?.remark || "-"}</p>
                      </div>
                      <div className="mb-3">
                        <h4 className='fw-bold'>Status</h4>
                        <p className='text-capitalize mb-0'>{data?.status || "-"}</p>
                      </div>
                    </div>

                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewTransactionModel
