import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { createOrUpdateNotificationApi, getDocsCategoryByTypeApi, updateFormPriceByIdApi } from '../../../utils/ApiEndpointFunctions';
import Header from '../../common/Header';
import toast from "react-hot-toast";
import Spinwheel from '../../common/Spinwheel';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { formCategoryPriceIntialValue, formCategoryPriceValidationSchema, } from '../../common/Validation';


function FormCategory() {
  const [tableSpin, setTableSpin] = useState(true);
  const [search, setSearch] = useState("");
  const [tabelData, setTableData] = useState([]);
  const priceUpdateModelId = "confirmSendModalId"
  const [submitSpin, setSubmitSpin] = useState(false)


  const getFormCategoryList = async (search = "") => {
    try {
      setTableSpin(false)
      const res = await getDocsCategoryByTypeApi("", "", search)
      if (res.status === 200) {
        setTableData(res.data?.data?.categories)
      }
    } catch (error) {
      
    }
    setTableSpin(true)
  }


  const priceformik = useFormik({
    initialValues: formCategoryPriceIntialValue,
    onSubmit: async (values) => {
      try {
        setSubmitSpin(true)
        const res = await updateFormPriceByIdApi(values)
        if (res.status === 200) {
          toast.success(res.data.message)
          getFormCategoryList("")
          document.getElementById(priceUpdateModelId).click();
        }
      } catch (error) {
        if (error?.response) toast.error(error?.response?.data?.message);
        else toast.error("Something went wrong");
      }
      setSubmitSpin(false)
    },
    validationSchema: formCategoryPriceValidationSchema
  })



  const onChange = async (e) => {
    let search = e.target.value;
    setSearch(search)
    getFormCategoryList(search)

  };
  const debouncedOnChange = debounce(onChange, 500);

  

  useEffect(() => {
    getFormCategoryList("")
  }, [])

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Users" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">
                    <i className="fe fe-home"></i> Users
                  </Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Form-categories
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className="position-relative">
                  <input
                    className="form-search me-3"
                    type="search"
                    name=""
                    onChange={debouncedOnChange}
                    placeholder="Search"
                  />
                  <img
                    className="users-search"
                    src="/images/Icon feather-search.svg"
                    alt=""
                  />
                </div>

              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr className='col-1'>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th className='col-2'>
                      <span
                        className="list-sort text-muted"
                      >
                        category
                      </span>
                    </th>

                    <th className='col-2'>
                      <span
                        className="list-sort text-muted"
                      >
                        form
                      </span>
                    </th>

                    <th className='col-3' colSpan={2}>
                      <span
                        className="list-sort text-muted"
                      >
                        price
                      </span>
                    </th>

                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {tabelData?.length > 0 ? tabelData?.map((ele, index) => {
                        return (<tr key={index} >
                          <td className='col-1'>{index + 1}</td>
                          <td className='col-2'>
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.type ? ele?.type : "-"}
                            </span>
                          </td>



                          <td className='col-2' >
                            <span
                              className="item-name text-reset"
                            >
                              {ele?.name ? ele?.name : "-"}
                            </span>

                          </td>
                          <td className='col-3' >
                            <span
                              className="item-phone text-reset"
                            >
                              {ele.price ? ele?.price : "-"}
                            </span>

                          </td>

                          <td className="text-end col-1">
                            <div className="dropdown">
                              <span
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-end">
                                <div
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  style={{ cursor: "pointer" }}
                                  data-bs-target={`#${priceUpdateModelId}`}
                                  onClick={() => {
                                    priceformik.setValues(ele)
                                  }}>
                                  Update price
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>

            </div>
          </div>

        </div>
      </div>

      <div className="modal fade" id={priceUpdateModelId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body  block-user">
              <form action="" onSubmit={priceformik.handleSubmit} >

                <div className='d-flex'><label htmlFor="price" className="form-label">Price</label></div>
                <div className="position-relative"><input className="form-control border border-1" type="price" id="price" placeholder="Enter price" name="price" value={priceformik.values.price} onChange={priceformik.handleChange} onBlur={priceformik.handleBlur} />
                  {priceformik.touched.price && priceformik.errors.price ? (<div className='req-error'>{priceformik.errors.price}</div>) : null}
                </div>
                <br />


                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={submitSpin}>{submitSpin ? <Spinwheel sizeClass="spin-small" /> : "Submit"}</button>
                  <div className="btn-custom w-auto mt-2" data-bs-dismiss="modal"  >Back</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default FormCategory